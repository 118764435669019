export const channels = {
  'localhost:3000/se': c('sv-SE'),
  'localhost:3000/en': c('en-GB'),
  'localhost:3000/eu': c('en-GB'),
  'localhost:3000/de': c('de-DE'),
  'storefront.eu.ngrok.io/se': c('sv-SE'),
  'storefront.eu.ngrok.io/eu': c('en-GB'),
  'storefront.eu.ngrok.io/b2b_se': c('sv-SE'),
  'distancify-kristoffer.eu.ngrok.io/se': c('sv-SE'),
  'lindblom-3000.eu.ngrok.io/se': c('sv-SE'),
  'distancify-vlad.eu.ngrok.io/se': c('sv-SE'),
  'distancify-vlad.eu.ngrok.io/en': c('en-GB'),
  'beta.kidsconcept.com/se': c('sv-SE'),
  'beta.kidsconcept.com/en': c('en-GB'),
  'kidsconcept.com/se': c('sv-SE'),
  'kidsconcept.com/en': c('en-GB'),
  'kidsconcept.com/fr': c('fr-FR', 'en_GB'),
  'kidsconcept.com/de': c('de-DE', 'en_GB'),
  'retail-staging.kidsconcept.com/se': c('sv-SE'),
  'retail-staging.kidsconcept.com/en': c('en-GB'),
  'retail-staging.kidsconcept.com/fr': c('fr-FR', 'en_GB'),
  'retail-staging.kidsconcept.com/de': c('de-DE', 'en_GB'),

  'localhost:3000/b2b_se': c('sv-SE'),
  'localhost:3000/b2b_en': c('en-GB'),
  'localhost:3000/b2b_fr': c('fr-FR'),

  'wholesale-staging.kidsconcept.com/se': c('sv-SE'),
  'wholesale-staging.kidsconcept.com/en': c('en-GB'),
  'wholesale-staging.kidsconcept.com/fr': c('fr-FR', 'en_GB'),

  'beta.b2b.kidsconcept.com/se': c('sv-SE'),
  'beta.b2b.kidsconcept.com/en': c('en-GB'),
  'beta.b2b.kidsconcept.com/fr': c('fr-FR', 'en_GB'),

  'b2b.kidsconcept.com/se': c('sv-SE'),
  'b2b.kidsconcept.com/en': c('en-GB'),
  'b2b.kidsconcept.com/fr': c('fr-FR', 'en_GB'),
};

function c(language: string, fallbackLanguage?: string): {
  language: string
  fallbackLanguage?: string
} {
  return {
    language,
    fallbackLanguage,
  };
}