import { nextTick, onMounted, onUnmounted, Ref } from "vue";

export function useLazyLoad(el: Ref<HTMLElement|null>, callback: () => void) {
  let lazyLoadingObserver: IntersectionObserver | null = null;

  onMounted( async() => {
    if (el.value && 'IntersectionObserver' in window) {
      await nextTick()
      lazyLoadingObserver = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            callback();
            observer.disconnect();
          }
        });
      }, {
        rootMargin: '0px 0px 400px 0px',
      });

      lazyLoadingObserver.observe(el.value);
    } else {
      callback();
    }
  })

  onUnmounted(() => {
    lazyLoadingObserver?.disconnect();
  });
}