<script>
import { settingsKey } from "@/keys";
import ProductCard from "../ProductCard";

export default {
  components: {
    ProductCard,
  },
  props: {
    searchResult: {
      type: Object,
    },
  },
  inject: {
    settings: settingsKey,
  },
  computed: {
    noSearchHitsImage() {
      return this.settings?.noSearchHitsDesktopImage?.filename;
    },
    products() {
      return this.searchResult?.productSearchV2?.products || [];
    },
    productHits() {
      return this.searchResult?.productSearchV2?.hits || 0;
    },

    pages() {
      return this.searchResult?.pageSearch?.pages || [];
    },
    pageHits() {
      return this.pages.length > 0;
    },
    facetSuggestions() {
      return this.searchResult?.productSearchV2?.facetSuggestions || [];
    },
    searchString() {
      return this.searchResult?.searchString || "";
    },
    productHitsLabel() {
      return this.$replaceTokens(
        this.$globalTexts.search__see_all || "search__see_all {{hits}}",
        {
          hits: this.productHits,
        }
      );
    },
    hasAnySearchResults() {
      return (
        this.products.length > 0 ||
        this.facetSuggestions.length > 0 ||
        this.pages.length > 0
      );
    },
  },
  methods: {
    performFullSearch() {
      this.$mitt.emit("perform-full-search", this.searchString);
      this.$nextTick(() => {
        this.$mitt.emit("close-search");
      });
    },
  },
};
</script>

<template>
  <div class="search-view-results">
    <div class="search-view-results__links">
      <div class="search-view-results__facets">
        <div class="search-view-results__facet-suggestions">
          <template
            v-for="facet in facetSuggestions"
            :key="facet.field"
            class="search-view-results__facet-suggestion"
          >
            <div
              v-for="facetValue in facet.suggestedValues"
              :key="facetValue.value"
              class="search-view-results__facet-value-suggestion"
              @click="
                $mitt.emit('apply-facet-suggestion', {
                  facetId: facet.field,
                  facetValueId: facetValue.value,
                })
              "
            >
              {{ facetValue.label }}
            </div>
          </template>
        </div>

        <div
          class="search-view-results__all-product-hits"
          v-if="productHits"
          @click="performFullSearch()"
        >
          {{ productHitsLabel }}
        </div>
      </div>
      <div class="search-view-results__pages" v-if="pageHits">
        <div class="search-view-results__page-caption">
          {{
            $globalTexts.search__page_results_title ||
            "search__page_results_title"
          }}
        </div>
        <div class="search-view-results__page-links">
          <router-link
            v-for="page in pages"
            :key="page.pageId"
            :to="page.url"
            class="search-view-results__page-suggestion"
            @click="$mitt.emit('clear-search-input')"
          >
            <span class="search-view-results__page-suggestion-text">
              {{ page.pageName }}
            </span>
            <span class="search-view-results__page-suggestion-spacer">|</span>
          </router-link>
        </div>
      </div>
    </div>
    <div
      class="search-view-results__products-wrapper"
      v-if="products.length > 0"
    >
      <div class="search-view-results__products">
        <product-card
          class="search-view-results__product"
          v-for="product in products"
          :key="product.articleNumber"
          :product="product"
          :hide-related-articles="true"
          @click="
            $mitt.emit('search-result-clicked');
            $mitt.emit('clear-search-input');
          "
        />
      </div>
    </div>
    <div class="search-view-results__no-result" v-if="!hasAnySearchResults">
      <div>
        {{ $globalTexts.search__no_hits || "search__no_hits" }}
      </div>
      <div
        v-if="noSearchHitsImage"
        class="search-view-results__no-result-image"
      >
        <img :src="noSearchHitsImage" />
      </div>
    </div>
  </div>
</template>

<style>
.search-view-results {
  background-color: var(--color-neutrals-00);
  margin-top: 1.25rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-height: calc(100vh - 102px);
  overflow-y: auto;
  padding: 0 10px;
}

.search-view-results__page {
  padding-right: 0.5rem;
}

.search-view-results__all-product-hits {
  cursor: pointer;
  font-size: 16px;
  font-family: "ProximaNova-Bold";
  line-height: 24px;
  letter-spacing: 0px;
  margin-bottom: 0.5rem;
}

.search-view-results__facets {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1190px;
}

.search-view-results__facet-suggestions {
  display: flex;
  max-width: 75%;
  flex-wrap: wrap;
}

.search-view-results__facet-suggestion {
  display: flex;
  flex-wrap: wrap;
}

.search-view-results__facet-value-suggestion {
  color: var(--color-main-black);
  border: 1px solid var(--color-neutrals-50);
  padding: 0.5rem 1rem;
  border-radius: 1.25rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  font-size: 16px;
  font-family: "ProximaNova-Regular";
}

.search-view-results__pages {
  margin: 1rem auto 0rem;
  max-width: 1185px;
}

.search-view-results__page-suggestion,
.search-view-results__page-suggestion-text {
  padding-right: 0.75rem;
  text-decoration: none;
}

.search-view-results__page-suggestion-spacer {
  color: var(--color-main-black);
}

.search-view-results__page-links
  > a:nth-last-child(1)
  span.search-view-results__page-suggestion-spacer {
  display: none;
}

.search-view-results__page-caption {
  font-size: 16px;
  font-family: "ProximaNova-Bold";
  color: #b5b6b8;
  line-height: 24px;
  padding-bottom: 0.35rem;
}

.search-view-results__page-suggestion {
  font-size: 14px;
  font-family: "ProximaNova-Regular";
  color: var(--color-main-black);
}

.search-view-results__no-result {
  text-align: center;
  font-family: "ProximaNova-Bold";
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 0.5rem;
}

.search-view-results__no-result-image {
  padding: 1.5rem;
}

@media (--tabletAndDesktop) {
  .search-view-results__products {
    display: flex;
    flex-wrap: wrap;
    margin: 1rem 0 1rem;
  }

  .search-view-results__products-wrapper {
    max-width: 1210px;
    width: 100%;
    margin: 0 auto;
    padding-bottom: 20px;
  }

  .search-view-results__product {
    width: 20%;
    margin-bottom: 10px;
    padding: 0 0.35rem;
  }
}

@media (max-width: 1279px) {
  .search-view-results {
    max-height: calc(100vh - 92px);
  }
}
</style>
