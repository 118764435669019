import { provide, ref, watch, onMounted } from 'vue';
import useContext from '@/composables/useContext';

import { isSearchOpenKey, searchStringKey, searchResultKey } from '@/keys';


export default function () {
  const { route } = useContext();

  const searchString = ref('');
  provide(searchStringKey, searchString);

  const searchResult = ref(null);
  provide(searchResultKey, searchResult);

  const isSearchOpen = ref(false);
  provide(isSearchOpenKey, isSearchOpen);

  watch(
    () => route.pathname,
    () => {
      isSearchOpen.value = false;
    }
  );

  return {
    isSearchOpen,
    searchString,
    searchResult,
  };
}
