<script setup>
import { onMounted, onUnmounted, ref, provide, reactive } from 'vue';

import { activeDesktopFlyoutKey } from '@/keys';

import useContext from "@/composables/useContext";

import Ribbon from './Ribbon.vue';
import Header from './Header.vue';
import GeoIpRedirect from '../GeoIpRedirect.vue';

const { environmentOptions } = useContext();

const hideRibbon = ref(false);
const mounted = ref(false);

onMounted(() => {
  mounted.value = true;
  window.addEventListener('scroll', onScroll);
  window.addEventListener('click', closeFlyout);
});

onUnmounted(() => {
  window.removeEventListener('scroll', onScroll);
  window.removeEventListener('click', closeFlyout);
});

let lastPosition = 0;
let origin = 0;
function onScroll(e) {
  if (turnedBackUpTowardsOrigin()) {
    origin = window.scrollY;
    hideRibbon.value = false;
  } else if (turnedBackDownTowardsOrigin()) {
    origin = window.scrollY;
  }

  if (scrolledPastOrigin()) {
    hideRibbon.value = true;
  }

  lastPosition = window.scrollY;

  function turnedBackUpTowardsOrigin() {
    return lastPosition > window.scrollY && origin < lastPosition;
  }

  function turnedBackDownTowardsOrigin() {
    return lastPosition < window.scrollY && origin > lastPosition;
  }

  function scrolledPastOrigin() {
    return origin + 100 < window.scrollY;
  }
}

const activeDesktopFlyout = reactive({
  isPinned: false,
  uid: null,
});
provide(activeDesktopFlyoutKey, activeDesktopFlyout);

let timer;
function startTimer() {
  if (!activeDesktopFlyout.isPinned) {
    timer = setTimeout(() => {
      activeDesktopFlyout.uid = null;
    }, 500);
  }
}

function stopTimer() {
  clearTimeout(timer);
}
function closeFlyout() {
  if (activeDesktopFlyout.uid) {
    activeDesktopFlyout.uid = null;
  }
}
</script>

<template>
  <header
    class="site-header"
    :class="{
      'site-header--minimized': hideRibbon,
      'site-header--desktop-flyout': activeDesktopFlyout.uid,
    }"
    @mouseleave="startTimer()"
    @mouseenter="stopTimer()"
    @click.stop
  >
    <Ribbon />
    <Header />
    <GeoIpRedirect v-if="!environmentOptions.requireLogin && mounted" :scrolledUp="hideRibbon" />
  </header>
</template>

<style>
.site-header {
  position: sticky;
  top: 0;
  transition: top 100ms;
  box-shadow: 0px 5px 16px rgba(26, 26, 26, 0.05);
  z-index: 100;
}

.site-header--minimized {
  top: -35px;
}

.site-header.site-header--desktop-flyout {
  box-shadow: none;
}

@media (--phone) {
  .site-header {
    overflow-x: hidden;
  }
}
</style>
