<script>
import SearchViewInput from './SearchViewInput.vue'
import SearchViewDesktopResults from './SearchViewDesktopResults.vue'
import {
  searchStringKey,
  searchResultKey,
  isSearchOpenKey,
  emitterKey,
} from '@/keys'

let timer = null;

export default {
  components: {
    SearchViewInput,
    SearchViewDesktopResults,
  },
  inject: {
    searchString: searchStringKey,
    searchResult: searchResultKey,
    isSearchOpen: isSearchOpenKey,
    mitt: emitterKey,
  },
  data: () => ({
    showSearchView: false,
    searchResultModel: null,
  }),
  watch: {
    searchResult() {
      if (this.searchResult) {
        setTimeout(() => {
          this.showSearchView = true
        }, 50)
      } else {
        setTimeout(() => {
          this.showSearchView = false
        }, 150)
      }
    },
    showSearchView(val) {
      if (val) {
        window.addEventListener('mouseover', this.closeIfMouseInOverlay)
      } else {
        window.removeEventListener('mouseover', this.closeIfMouseInOverlay)
      }
    },
  },
  methods: {
    closeIfMouseInOverlay(e) {
      if (this.isSearchOpen) {
        if (this.$refs?.searchViewDesktop?.isSameNode(e.target)) {
          timer = setTimeout(() => {
            this.mitt.emit('close-search')
            this.mitt.emit('clear-search-input')
            this.mitt.emit('blur-search-input')
          }, 500)        
        }
        else {
          if(timer) {
            clearTimeout(timer);
          }
        }
      }
    },
  },
}
</script>

<template>
  <div 
    class="search-view-desktop"
    :class="{ 'search-view-desktop__background--open': showSearchView }"
    v-if="isSearchOpen" 
    ref="searchViewDesktop"
  >
    <div
      class="search-view-desktop__wrap"
      :class="{ 'search-view-desktop--open': showSearchView }"
    >
      <div
        class="search-view-desktop__content"
        v-show="isSearchOpen && searchResult"
      >
        <search-view-desktop-results :search-result="searchResult" />
      </div>
    </div>
  </div>
</template>

<style>
.search-view-desktop {
  position: absolute;
  top: 102px;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  pointer-events: all;
  opacity: 0;
  transition: opacity 300ms;
}

.search-view-desktop__background--open {
  opacity: 1;
}

.search-view-desktop__wrap {
  background-color: var(--color-neutrals-00);
  width: 100%;
  position: absolute;
  transform: translateY(-100%);
  transition: transform 300ms;
}

.search-view-desktop--open {
  transform: translateY(0);
  box-shadow: 0px 5px 16px rgba(26, 26, 26, 0.05);
}

@media (max-width: 1279px) {
  .search-view-desktop {
    top: 92px;
  }
}
</style>
