
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    tag: {
      type: Object,
      default: {},
    }
  },
  computed: {
    tagClass() {
      return `product-tag--${this.tag?.id?.toLowerCase() || ''}`;
    },
    tagName() {
      return this.tag?.tagName || "";
    },
  },
});
