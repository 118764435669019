import { inject, ref } from 'vue';
import { format } from 'date-fns';
import { cartKey } from '@/keys';
import { dateLocales } from '../utils';
import useChannel from '@/composables/useChannel';

export default function () {
  const cart = inject(cartKey, ref({}));
  const { channel } = useChannel();

  return {
    formatDate(date: Date, dateFormat: string) {
      const locale = cart.value?.locale || channel.value?.locale || 'en-US';
      const language: Locale = (dateLocales as any)[locale] as Locale;
      return format(date, dateFormat, { locale: language });
    },
    formatPrice(value?: number, decimals?: number) {
      if (value !== 0 && !value) {
        return '';
      }
      if (!cart.value) {
        return value;
      }

      if (!decimals) {
        decimals = 2;
      }

      const locale = cart.value?.locale || channel.value?.locale || 'sv-SE';
      const currencyId = cart.value?.currency?.id || 'SEK';

      return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currencyId,
        maximumFractionDigits: decimals,
        minimumFractionDigits: 0,
      }).format(value);
    },
  };
}
