import {
  fetchPageBuilder,
  fetchPage,
  Route,
} from "@drapejs/core";

import { channels } from "./config";

/**
 * Extracts the protocol from a URL
 * @param input A *absolute* URL
 * @returns the protocol of the URL
 */
export function getProtocol(url: string) {
  const arr = url.split("//");
  return arr[0];
}

/**
 * Extracts the hostname from a URL
 * @param input A *absolute* URL
 * @returns the hostname of the URL
 */
export function getHostName(url: string) {
  const arr = url.split("://");
  arr.shift();
  const arr2 = arr.join("").split("/");
  return arr2[0]
}

/**
 * Extracts the path from a URL
 * @param input A *absolute* URL
 * @returns the path of the URL
 */
export function getPath(url: string) {
  const protocol = getProtocol(url);
  const hostName = getHostName(url);
  return url.replace(`${protocol}//${hostName}`, '');
}

/**
 * This method extendes fetchPageBuilder by adding the root folder to the path for fetching a Storyblok story.
 * @param currentRoute 
 * @param relativePath is the relative path to the story from the base folder
 * @param query 
 * @returns 
 */
export function fetchPageBuildWithStoryblokFolderPrefix(currentRoute: Route, relativePath: string) {
  const payload = fetchPageBuilder(currentRoute.protocol, currentRoute.host, currentRoute.pathname, currentRoute.query);
  const channelConfig = getChannelConfiguration(currentRoute);

  if (channelConfig?.urlPrefix) {
    payload.path = `/${channelConfig?.urlPrefix}${relativePath}`;
    payload.cacheKey = `/${channelConfig?.urlPrefix}${relativePath}`;
  }

  return payload;
}

export function getChannelConfigurationByFetchPage(command: fetchPage) {
  let channelConfig = channels?.[command.host];

  if (channelConfig) {
    return {
      ...channelConfig,
      urlPrefix: ""
    };
  }

  const pathSegments = command.path?.split("/").filter((s) => s) || [];
  channelConfig = channels?.[command.host + "/" + pathSegments[0]];

  if (channelConfig) {
    return {
      ...channelConfig,
      urlPrefix: pathSegments[0]
    };
  }

  return null;
}

export function getChannelConfiguration(currentRoute: Route) {
  const command = fetchPageBuilder(currentRoute.protocol, currentRoute.host, currentRoute.pathname, currentRoute.query);
  return command ? getChannelConfigurationByFetchPage(command) : undefined;
}
