import { defineReceiver, defineCommand } from '@drapejs/invoker';

import {
  request,
  query,
  getCustomFields,
} from '@distancify/drapejs-litium/lib/receivers/index';
import { gql } from 'graphql-request';

export const commands = {
  performQuickSearch: defineCommand<{
    url: string;
    categorySystemId: string;
    take: number;
    sort: string;
    searchPhrase: string;
    /**
     * The cache key to store the category data in
     */
    cacheKey?: string;
  }>('performQuickSearch'),
  getProducts: defineCommand<{
    url: string;
    take: number;
    searchPhrase: string;
  }>('getProducts'),
};

export const receivers = {
  performQuickSearch: defineReceiver(
    commands.performQuickSearch,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(gql`
          query performQuickSearch(
            $url: String!
            $rootCategory: String!
            $take: Int!
            $sort: String!
            $searchPhrase: String!
          ) {
            session(url: $url) {
              productSearchV2(
                rootCategory: $rootCategory
                take: $take
                sort: $sort
                searchPhrase: $searchPhrase
              ) {
                hits
                facetSuggestions {
                  field
                  suggestedValues {
                    value
                    label
                  }
                }
                products {
                  url
                  fields
                  variants {
                    id
                    fields
                    price {
                      listPrice
                      campaignPrice
                    }
                  }
                }
              }
              storySearch(take: $take, searchPhrase: $searchPhrase) {
                take
                hits
                totalHits
                searchPhrase
                stories {
                  pageName: name
                  slug
                  localizedSlug
                  pageId
                  pageSystemId
                }
              }
            }
          }
        `),
        {
          url: `${(<any>command).protocol}//${(<any>command).host}${
            (<any>command).path
          }`,
          rootCategory: command.categorySystemId,
          take: command.take,
          sort: (<any>command).query?.sort || 'relevance',
          searchPhrase: decodeURIComponent((<any>command).query.search || ''),
        }
      );

      return result.session;
    },
    'litium'
  ),
  getProducts: defineReceiver(
    commands.performQuickSearch,
    async function (command, data) {
      try {
        const result = await request(
          this.cache,
          query(gql`
            query performQuickSearch($url: String!, $take: Int!, $sort: String!, $searchPhrase: String!) {
              session(url: $url) {
                productSearchV2(take: $take, sort: $sort, searchPhrase: $searchPhrase) {
                  hits
                  products {
                    url
                    variants {
                      id
                      fields(ids: "displayName, _images, packedQuantity")
                    }
                    variant {
                      id
                      stock {
                        totalStockAvailable
                        expectedShippingDateTime
                        currentStock {
                          amount
                          date
                        }
                        incomingStock {
                          amount
                          date
                        }
                      }
                    }
                  }
                }
              }
            }
          `),
          {
            url: command.url,
            take: command.take,
            sort: 'relevance',
            searchPhrase: command.searchPhrase || '',
          }
        );

        return result.session.productSearchV2;
      } catch (err) {
        console.error(err);
        throw 'FAILED';
      }
    },
    'litium'
  ),
};