import createApp from './main'
import { EnvironmentOptions } from './EnvironmentOptions'
import StandardLayout from './layouts/RetailLayout.vue'
import AddToCart from './components/pdp/RetailAddToCart.vue'
import StockStatus from './components/pdp/StockStatus.vue'
import HeaderActions from './components/SiteHeader/RetailHeaderActions.vue'
import ShoppingPreferences from '@/components/ShoppingPreferences/RetailShoppingPreferences.vue'

export default function (
  environmentOptions: EnvironmentOptions,
  workerFactory?: () => Worker,
) {
  const app = createApp(
    environmentOptions,
    {
      StandardLayout,
      AddToCart,
      HeaderActions,
      StockStatus,
      ShoppingPreferences,
    },
    workerFactory,
  )

  return app
}