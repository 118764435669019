<script>
function roundAndFormat(locale, currency, value, decimals) {
  try {
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency,
      maximumFractionDigits: decimals,
      minimumFractionDigits: decimals,
    }).format(value);
  } catch {
    return value;
  }
}

const Currency = (props, context) => {
  const configuredDecimals = props.numberOfDecimals || 0;
  const decimals =
    props.value == parseInt(props.value) ? 0 : configuredDecimals;
  const locale = props.locale;
  const currency = props.currency;
  const formatted = roundAndFormat(locale, currency, props.value, decimals);
  const visibilityStyle =
    locale && currency
      ? {}
      : {
          visibility: "hidden",
        };

  const classes = [context.attrs.staticClass].filter((f) => f).join(" ");

  return (
    <span class={classes} style={visibilityStyle}>
      {formatted}
    </span>
  );
};

Currency.props = ["value", "locale", "currency", "numberOfDecimals"];

export default Currency;
</script>
