const drapejs = require('@drapejs/core');

const environment = drapejs.getServerItem('__environment') || {
  storyblokToken: process.env.STORYBLOK_TOKEN, 
  litiumBaseUrl: process.env.LITIUM_URL,
  contentRootPath: process.env.SITE_ID,
  requireLogin: process.env.SITE_ID === 'wholesale',
  gtmContainerId: process.env.GTM_CONTAINER_ID,
};

module.exports = environment