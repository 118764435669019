import { InjectionKey, Ref, Component } from 'vue';
import { DataSourceEntries } from '@distancify/drapejs-storyblok';
import { Emitter } from 'mitt';

export const websiteTextsKey: InjectionKey<Ref<DataSourceEntries>> =
  Symbol('websiteTexts');

interface CartData {
  isEmpty: boolean;
}

interface Cart {
  data: CartData;
}

interface MetaProductUsp {
  USP: { name: String; value: String };
  USPSummary: String;
  USPImage: {
    url: String;
    id: String;
    templateId: String;
    width: Number;
    height: Number;
  };
  USPDescription: String;
  DisplayPriority: Number;
}

interface MetaProductCertification {
  Certification: { name: String; value: String };
  CertificationSummary: String;
  CertificationImage: {
    url: String;
    id: String;
    templateId: String;
    width: Number;
    height: Number;
  };
  CertificationDescription: String;
  DisplayPriority: Number;
}
interface SafetyText {
  SafetyText: { name: String; value: String };
  SafetyTextContent: String;
}
interface MetaProduct {
  uspMap: Map<String, MetaProductUsp>;
  certificationMap: Map<String, MetaProductCertification>;
  safetyTexts: Map<String, SafetyText>;
}

export interface Settings {
  materialsAndCareContent: [
    {
      Name: String;
      Fields: String;
    }
  ];
  measurementsAndDetailsContent: [
    {
      Name: String;
      Fields: String;
    }
  ];
  extraMaterialContent: [
    {
      Name: String;
      Fields: String;
    }
  ];
  favicon: Object;
  footerLinks: any[];
  scripts: any[];
}

export const cartKey: InjectionKey<Ref<any>> = Symbol('cart');

export const settingsKey: InjectionKey<Ref<Settings>> = Symbol('settingsKey');
export const metaProductKey: InjectionKey<Ref<MetaProduct>> =
  Symbol('metaProductKey');
export const emitterKey: InjectionKey<Emitter<any>> = Symbol('mitt');
export const channelKey: InjectionKey<Ref<any>> = Symbol('channel');
export const userInfoKey: InjectionKey<Ref<any>> = Symbol('userInfo');

export const redirectToLoginPageKey: InjectionKey<
  (query: any) => Promise<void>
> = Symbol('redirectToLoginPage');
export const userKey: InjectionKey<Ref<any>> = Symbol('userKey');

export const isWholesaleCartOpenKey: InjectionKey<Ref<any>> = Symbol(
  'isWholesaleCartOpenKey'
);

export const wholesaleCartOptionsKey: InjectionKey<Ref<any>> = Symbol(
  'wholesaleCartOptionsKey'
);

export const countrySwitcherKey: InjectionKey<Ref<any>> = Symbol('countrySwitcher');
export const activeDesktopFlyoutKey: InjectionKey<{ isPinned: boolean, uid: string | null}> = Symbol('activeDesktopFlyout');
export const replaceTokensKey: InjectionKey<(text: string, args?: { [key: string]: string }) => string> = Symbol("replaceTokens");

export const isSearchOpenKey: InjectionKey<Ref<boolean>> = Symbol("isSearchOpen");
export const searchStringKey: InjectionKey<Ref<string>> = Symbol("searchStringKey");
export const searchResultKey: InjectionKey<Ref<any>> = Symbol("searchResultKey");