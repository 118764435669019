<script>
import SearchViewInput from './SearchViewInput.vue'
import SearchViewPhoneResults from './SearchViewPhoneResults.vue'
import DrawerLayer from '@distancify-storefront/ui-drawer-layer'
import {
  searchStringKey,
  searchResultKey,
  isSearchOpenKey,
  emitterKey,
} from '@/keys'

export default {
  components: {
    SearchViewInput,
    SearchViewPhoneResults,
    DrawerLayer,
},
  inject: {
    searchString: searchStringKey,
    searchResult: searchResultKey,
    isSearchOpen: isSearchOpenKey,
    mitt: emitterKey,
  },
  watch: {
    isSearchOpen(){
      if(this.isSearchOpen){
        this.openSearchView();
      }
    },
  },
  methods: {
    openSearchView() {
      this.$nextTick(() => this.mitt.emit('focus-search-input'))
    },
    closeSearchView() {
      this.mitt.emit('close-search')
    },
  },
  mounted() {
    this.mitt.on('open-search-view', this.openSearchView)
  },
  beforeUnmount() {
    this.mitt.off('open-search-view', this.openSearchView)
  },
}
</script>

<template>
  <drawer-layer v-model="isSearchOpen" :showCloseIcon="false" modalClass="search-view__drawer-phone">
    <div class="search-view-phone__wrapper">
      <div class="search-view-phone">
        <div class="search-view-phone__exit" @click="closeSearchView">
          <img class="search-view-phone__exit-arrow" src="@/static/icons/arrow.svg" alt="">
        </div>
        <search-view-input class="search-view-phone__input" />
      </div>
      <div class="search-view-phone__horizontal-separator"></div>
      <div class="search-view-phone__content" ref="searchViewPhoneContent">
        <search-view-phone-results :search-result="searchResult" />
      </div>
    </div>
  </drawer-layer>
</template>

<style>
.search-view-phone__wrapper {
  height: 100vh;
  background: var(--color-neutrals-00);
  overflow: hidden;
}

.search-view-phone {
  display: flex;
}

.search-view-phone__exit {
  height: 55px;
  border-right: 1px solid var(--color-neutrals-40);
  width: 55px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.search-view-phone__exit-arrow {
  transform: rotate(180deg);
  width: 16px;
}

.search-view-phone__horizontal-separator {
  border-bottom: 1px solid var(--color-neutrals-40);
}

.search-view-phone__input {
  flex-grow: 1;
}

@media (min-width: 768px) {
  .search-view__drawer-phone {
    display: none;
  }
}
</style>
