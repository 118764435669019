<script>
import TextInput from "./forms/TextInput.vue";

export default {
  components: {
    TextInput,
  },
  props: {
    quantity: {
      type: Number,
    },
    narrowStyle: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
    },
  },
  data: () => ({
    quantityModel: 0,
    errorModel: "",
    changeQuantityTimeout: null,
  }),
  emits: ["update:quantity"],
  inject: {
    $isDisabledReactive: "$isDisabledReactive" || false,
  },
  computed: {
    isDisabled() {
      return this.$isDisabledReactive;
    },
  },
  methods: {
    setAmount(amount) {
      if (this.isDisabled) {
        return;
      }
      const { error } = this;
      this.quantityModel = amount;
      this.$nextTick(() => {
        this.errorModel = error;
      });
    },
    subtract() {
      if (this.isDisabled) {
        return;
      }
      const { error } = this;
      this.quantityModel -= 1;
      this.$nextTick(() => {
        this.errorModel = error;
      });
    },
    add() {
      if (this.isDisabled) {
        return;
      }
      const { error } = this;
      this.quantityModel += 1;
      this.$nextTick(() => {
        this.errorModel = error;
      });
    },
  },
  watch: {
    error() {
      this.errorModel = this.error;
    },
  },
  created() {
    this.quantityModel = this.quantity;
    this.errorModel = this.error;

    this.$watch(
      () => this.quantityModel,
      (newValue) => {
        this.errorModel = "";

        if (this.changeQuantityTimeout) {
          clearTimeout(this.changeQuantityTimeout);
        }
        this.changeQuantityTimeout = setTimeout(() => {
          this.$emit("update:quantity", newValue);
        }, 300);
      }
    );
  },
};
</script>

<template>
  <div
    class="quantity-input"
    :class="{ 'quantity-input--narrow': narrowStyle }"
  >
    <div class="quantity-input__quantity-wrapper">
      <div class="quantity-input__quantity-adjuster" @click="subtract()">
        <img src="../static/icons/subtract.svg" />
      </div>
      <text-input
        type="number"
        class="quantity-input__input"
        v-model="quantityModel"
        :min="1"
        :class="{ 'quantity-input__input--disabled': isDisabled }"
        :disabled="isDisabled"
        :autoselect="true"
        :error-message="errorModel"
        :show-error-message="false"
      />
      <div
        class="quantity-input__quantity-adjuster"
        :class="{ 'quantity-input__quantity-adjuster--disabled': isDisabled }"
        @click="add()"
      >
        <img src="../static/icons/add.svg" />
      </div>
    </div>
  </div>
  <div v-if="errorModel" class="quantity-input_error">
    {{ errorModel }}
  </div>
</template>

<style>
.quantity-input {
  display: flex;
}

.quantity-input__quantity-wrapper {
  display: flex;
  width: 100%;
  border: 0.5px solid var(--color-neutrals-40);
}

.quantity-input__input input[type="number"] {
  width: 42px;
  text-align: center;
  -moz-appearance: textfield;
  padding: 0;
  height: 25px;
  border: none;
  border-radius: 0;
  border-left: 0.5px solid var(--color-neutrals-40);
  border-right: 0.5px solid var(--color-neutrals-40);
  font-size: 14px;
  font-family: "ProximaNova-Bold";
  -moz-appearance: textfield;
}

.quantity-input__input input::-webkit-outer-spin-button,
.quantity-input__input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.quantity-input__quantity-adjuster {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.quantity-input_error {
  text-align: center;
  margin-bottom: 0.5rem;
  color: var(--color-feedback-error);
  font-size: 12px;
}

.quantity-input__input--disabled,
.quantity-input__quantity-adjuster--disabled {
  cursor: not-allowed;
}
</style>
