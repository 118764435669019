<script>
import { OptionsComponent } from '@drapejs/core'
import { searchStringKey } from '@/keys'

export default {
  extends: OptionsComponent,
  props: {
    focus: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    searchStringModel: '',
    searchTimeout: null,
  }),
  inject: {
    searchString: searchStringKey,
  },
  watch: {
    searchString(newValue, oldValue) {
      if(this.searchStringModel != newValue){
        this.searchStringModel = newValue;
      }

      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout)
      }
      this.searchTimeout = setTimeout(() => {
        this.$mitt.emit('perform-quick-search', newValue)
      }, 300)
    },
    searchStringModel(value) {
      this.searchString = value;
    },
  },
  methods: {
    selectText() {
      if (this.$refs.searchInput) {
        this.$refs.searchInput.select()
      }
    },
    cancelSearch() {
      this.searchStringModel = ''
      this.$refs.searchInput.focus()
    },
    triggerFullSearch() {
      if (!this.searchStringModel) return
      this.$mitt.emit('perform-full-search', this.searchStringModel)
    },
    onEnterKeyPressed() {
      this.$refs.searchInput.blur()
      this.triggerFullSearch()
    },
    clearSearchInput() {
      this.searchStringModel = ''
    },
    focusSearchInput() {
      this.$refs?.searchInput?.focus()
    },
    blurSearchInput() {
      this.$refs?.searchInput?.blur()
    },
  },
  mounted() {
    this.searchStringModel = this.searchString;

    if (this.focus) {
      this.focusSearchInput()
    }

    this.$mitt.on('focus-search-input', this.focusSearchInput)
    this.$mitt.on('clear-search-input', this.clearSearchInput)
    this.$mitt.on('blur-search-input', this.blurSearchInput)
  },
  beforeUnmount() {
    this.$mitt.off('focus-search-input', this.focusSearchInput)
    this.$mitt.off('clear-search-input', this.clearSearchInput)
    this.$mitt.off('blur-search-input', this.blurSearchInput)
  },
}
</script>

<template>
  <div class="search-view-input">
    <div class="search-view-input__wrapper">
      <input
        type="text"
        class="search-view-input__input"
        ref="searchInput"
        v-model="searchStringModel"
        :placeholder="
          $globalTexts.global__search__placeholder ||
          'global__search__placeholder'
        "
        @focus="selectText()"
        @blur="$mitt.emit('search-performed')"
        @keyup.enter="onEnterKeyPressed"
      />
      <img
        class="search-view-input__delete-icon"
        src="../../static/icons/close.svg"
        @click="cancelSearch()"
        v-if="searchStringModel"
      />
      <img
        class="search-view-input__search-input-icon"
        src="../../static/icons/search-grey.svg"
        @click="triggerFullSearch()"
        v-if="!searchStringModel"
      />
    </div>
  </div>
</template>

<style>
.search-view-input {
  position: relative;
  padding: 0 20px 0 15px;
}

.search-view-input__wrapper {
  width: 100%;
  position: relative;
}

input[type='text'].search-view-input__input {
  border: none;
  outline: none;
  display: block;
  font-size: 18px;
  font-family: 'ProximaNova-Regular';
  padding: 16px 2rem 8px 0;
  padding-right: 2rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  border-radius: 0;
  color: var(--color-main-black);
  border-bottom: 1px solid var(--color-neutrals-40);
  height: 43px;
}

input[type='text'].search-view-input__input:focus {
  border-bottom: 1px solid var(--color-neutrals-100);
}

input[type='text'].search-view-input__input::placeholder {
  font-size: 18px;
  font-family: 'ProximaNova-Regular';
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  color: var(--color-text-light);
}

.search-view-input__search-input-icon {
  position: absolute;
  right: 0;
  top: 18px;
  width: 18px;
}

.search-view-input__delete-icon {
  position: absolute;
  top: 20px;
  right: 0px;
  width: 14px;
  cursor: pointer;
}

@media (--tabletAndDesktop) {
  .search-view-input {
    padding: 0 0 0;
    width: 360px;
    max-width: 360px;
    margin: -6px 2rem 0;
  }

  .search-view-input__wrapper {
    position: relative;
    width: 100%;
    max-width: 360px;
  }

  input[type='text'].search-view-input__input {
    height: 36px;
    padding: 1rem 2rem 8px 0;
    border: none;
    outline: none;
    display: block;
    width: 360px;
    font-size: 14px;
    border-bottom: 1px solid var(--color-neutrals-80);
  }

  input[type='text'].search-view-input__input::placeholder {
    font-size: 14px;
  }

  .search-view-input__search-input-icon {
    position: absolute;
    top: 10px;
    left: unset;
    right: 0px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  .search-view-input__delete-icon {
    position: absolute;
    top: 10px;
    right: 0px;
    width: 14px;
    height: 14px;
    cursor: pointer;
  }
}
</style>
