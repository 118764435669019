import Vue, { createSSRApp, defineAsyncComponent, Component } from 'vue';
import App from './App.vue';
import consola from 'consola';
import drapejs, { Options, OptionsComponent } from '@drapejs/core';
import Storyblok, {
  StoryblokOptions,
  linkResolution,
} from '@distancify/drapejs-storyblok';
import StandardComponents from '@distancify/storyblok-standard-components';
import { plugin as Litium } from '@distancify/drapejs-litium';
import MarkdownIt from 'markdown-it';
import environment from './env';

import { format } from 'date-fns';
import { dateLocales } from './utils';

import {
  settingsKey,
  emitterKey,
  websiteTextsKey,
  cartKey,
  channelKey,
  userInfoKey,
} from './keys';

import * as config from './config';
import './style/index.css';
import { EnvironmentOptions, environmentKey } from './EnvironmentOptions';

consola.level = process.env.NODE_ENV == 'development' ? 5 : 3;

const tokenRegex = /\{{(.+?)\}}/gi;

function createMd() {
  const md = new MarkdownIt({
    html: true,
    linkify: true,
    typographer: true,
    breaks: true,
  });

  md.renderer.rules.table_open = () => '<table class="markdown-table">\n';

  return md;
}

export interface SiteSpecificComponents {
  StandardLayout: Component;
  AddToCart: Component;
  HeaderActions: Component;
  StockStatus: Component;
  ShoppingPreferences: Component
}

export default function (env: EnvironmentOptions, components: SiteSpecificComponents, workerFactory?: () => Worker) {
  const create = <any>createSSRApp;

  return create(App)
    .use(drapejs, <Options>{
      pageComponents: {
        ContentPage: defineAsyncComponent(() => import('./pages/ContentPage.vue')),
        NotFound: defineAsyncComponent(() => import('./pages/NotFound.vue')),
        Error: defineAsyncComponent(() => import('./pages/Error.vue')),
        Category: defineAsyncComponent(() => import('./pages/Category.vue')),
        Product: defineAsyncComponent(() => import('./pages/Product.vue')),
        Checkout: defineAsyncComponent(() => import('./pages/Checkout.vue')),
        Receipt: defineAsyncComponent(() => import('./pages/Receipt.vue')),
        UnsubscribePpe: defineAsyncComponent(() => import('./pages/UnsubscribePpe.vue')),
        ExternalPayment: defineAsyncComponent(() => import('./pages/ExternalPayment.vue')),
        Auth: defineAsyncComponent(() => import('./pages/Auth.vue')),
        MyPages: defineAsyncComponent(() => import('./pages/MyPages.vue')),
        WholesaleCheckout: defineAsyncComponent(() => import('./pages/WholesaleCheckout.vue')),
        WholesaleReceipt: defineAsyncComponent(() => import('./pages/WholesaleReceipt.vue')),
      },
      routerIgnoreQueryParams: [/facets/],
      workerFactory,
    })
    .use(Storyblok, <StoryblokOptions>{
      blockComponents: {
        ...StandardComponents.blocks,
        ProductGrid: defineAsyncComponent(() => import('./blocks/ProductGrid.vue')),
        Text: defineAsyncComponent(() => import('./blocks/Text')),
        NewsletterSignup: defineAsyncComponent(() => import('./blocks/NewsletterSignup')),
        VideoPlayer: defineAsyncComponent(() => import('./blocks/VideoPlayer')),
        StoryContent: defineAsyncComponent(() => import('./blocks/StoryContent')),
        ConceptContent: defineAsyncComponent(() => import('./blocks/ConceptContent')),
        Ambassador: defineAsyncComponent(() => import('./blocks/Ambassador')),
        Carousel: defineAsyncComponent(() => import('./blocks/Carousel')),
        Button: defineAsyncComponent(() => import('./blocks/Button')),
        ButtonRepeater: defineAsyncComponent(() => import('./blocks/ButtonRepeater')),
        ProductsWithMedia: defineAsyncComponent(() => import('./blocks/ProductsWithMedia')),
        ChannelSpecificBlocks: defineAsyncComponent(() => import('./blocks/ChannelSpecificBlocks')),
        CookieDeclaration: defineAsyncComponent(() => import('./blocks/CookieDeclaration')),
      },
      token: env.storyblokToken,
      fetchDraft: process.env.NODE_ENV == 'development',
      linkResolution: linkResolution.url,
      channels: config.channels,
    })
    .use(Litium as any, {
      baseUrl: env.litiumBaseUrl,
      disableBlocks: true,
      blockComponents: {
        // Your block component names should match your block IDs in Litium
      },
    })
    .provide(environmentKey, environment)
    .component('StandardLayout', components.StandardLayout)
    .component('AddToCart', components.AddToCart)
    .component('StockStatus', components.StockStatus)
    .component('HeaderActions', components.HeaderActions)
    .component('ShoppingPreferences', components.ShoppingPreferences)
    .mixin({
      extends: OptionsComponent,
      data: () => ({
        width: 0,
      }),
      inject: {
        $cartReactive: {
          from: cartKey,
          default: {},
        },
        $websiteTextsRef: {
          from: websiteTextsKey,
          default: {},
        },
        $channelReactive: {
          from: channelKey,
          default: {},
        },
        $settings: {
          from: settingsKey,
          default: {},
        },
        $lastCartActionTimestampReactive: {
          default: {},
        },
        $emitter: {
          from: emitterKey,
          default: {},
        },
        $userInfoReactive: {
          from: userInfoKey,
          default: {},
        },
      },
      computed: {
        $cart() {
          return this.$cartReactive || {};
        },
        $mitt() {
          return this.$emitter;
        },
        $globalTexts() {
          return this.$websiteTextsRef || {};
        },
        $channel() {
          return this.$channelReactive || {};
        },
        $userInfo() {
          return this.$userInfoReactive || {};
        },
        $lastCartActionTimestamp() {
          return (this.$lastCartActionTimestampReactive && this.$lastCartActionTimestampReactive.data) || '';
        },
      } as any,
      methods: {
        $formatPrice(value: number, decimals?: number) {
          if (!decimals) {
            decimals = 2;
          }
          if (value !== 0 && !value) {
            return '';
          }
          if (!this.$cart) {
            return value;
          }

          const locale = this.$cart?.locale || this.$channel?.locale || 'en-US';
          const currencyId = this.$cart?.currency?.id || 'USD';

          return new Intl.NumberFormat(locale, {
            style: 'currency',
            currency: currencyId,
            maximumFractionDigits: decimals,
            minimumFractionDigits: 0,
          }).format(value);
        },
        $formatDate(date: Date, dateFormat: string) {
          const locale = this.$cart?.locale || this.$channel?.locale || 'en-US';
          const language: Locale = (dateLocales as any)[locale] as Locale;
          return format(date, dateFormat, { locale: language });
        },
        $renderMarkdown(text: string) {
          if (!text) {
            return '';
          }

          return createMd().render(text);
        },
        $renderMarkdownInline(text: string) {
          if (!text) {
            return '';
          }

          return createMd().renderInline(text);
        },
        $replaceTokens(text: string, args: any) {
          const replace = (textToFormat: string) => {
            if (!args) {
              args = {};
            }

            return textToFormat.replace(
              tokenRegex,
              (_, p1) => args[p1.toLowerCase()] || this.$globalTexts[p1.toLowerCase()] || ''
            );
          };

          return !text ? text : replace(text);
        },
        $toBlock(template: string) {
          if (!template) return '';

          return `block-${template.toLowerCase()}`;
        },
      } as any,
    });
}
