<script>
import ErrorMessage from "./ErrorMessage";
import ArrowExpandable from "../ArrowExpandable";
import TextInput from "./TextInput";

export default {
  components: {
    ArrowExpandable,
    ErrorMessage,
    TextInput,
  },
  props: {
    id: {
      type: String,
    },
    options: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      default: null,
    },
    searchPlaceholder: {
      type: String,
      default: null,
    },
    label: {
      type: String,
    },
    height: {
      type: [Number, String],
      default: "auto",
    },
    modelValue: {
      type: [String, Number],
    },
    errorMessage: {
      type: String,
    },
    addOption: {
      type: Boolean,
    },
    addOptionLabel: {
      type: String,
    },
    shrinkOnSelect: {
      type: Boolean,
      default: true,
    },
    enableSearch: {
      type: Boolean,
      default: false,
    },
    selectedPrefix: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      isExpanded: false,
      hasFocus: false,
      focusedIndex: -1,
      searchPhrase: "",
      dropdownStyle: {},
    };
  },
  watch: {
    isExpanded(isExpanded) {
      this.searchPhrase = ""; 
      this.buildDropdownStyle();
      
      if (isExpanded) {
        this.$nextTick(() => {
          this.$refs?.searchInput?.focus();
        });
      }
    },
  },
  computed: {
    filteredOptions() {
      if (!this.searchPhrase) {
        return this.options || [];
      }
      const searchPhrase = this.searchPhrase.toLowerCase();

      const options = (this.options || []).filter((f) => {
        return f.value && f.text.toLowerCase().includes(searchPhrase);
      });

      return options;
    },
    optionsLookup() {
      return this.options.reduce((p, c) => {
        p[c.value] = c;
        return p;
      }, {});
    },
    selected() {
      if (this.modelValue) {
        return this.getOptionObject(this.modelValue);
      }
      if (!this.modelValue && !!this.placeholder) {
        return {
          value: null,
          text: this.placeholder,
        };
      }
      if (this.options && this.options.length > 0) {
        return this.options[0];
      }
      return {};
    },
    showPlaceholder() {
      return !this.modelValue && !!this.placeholder;
    },
    isActive() {
      return this.hasFocus && this.isExpanded;
    },
  },
  methods: {
    enter(element) {
      element.style.visibility = "hidden";
      element.style.height = "auto";

      element.style.visibility = null;
      element.style.height = this.height;

      setTimeout(() => {
        element.style.height = this.height;
      });
    },
    leave(element) {
      setTimeout(() => {
        element.style.height = this.height;
      });
    },
    selectOption(option) {
      if (!option) return;
      this.$emit("update:modelValue", option.value);
      this.$emit("change", option);

      if (this.shrinkOnSelect) {
        this.isExpanded = false;
      }
    },
    shrink() {
      this.isExpanded = false;
    },
    open() {
      this.hasFocus = true;
      this.isExpanded = true;
    },
    isSelected(value) {
      return this.selected.value === value;
    },
    isFocused(index) {
      return this.focusedIndex === index;
    },
    getOptionObject(optionValue) {
      return (
        this.optionsLookup[optionValue] || {
          value: null,
          text: null,
        }
      );
    },
    scrollToFocusedElement() {
      if (!this.isExpanded) return;
      if (!this.$refs.dropdown) return;

      const elementHeight = 37;
      this.$refs.dropdown.scrollTo(0, this.focusedIndex * elementHeight);
    },
    checkKeypress(key) {
      if (!this.hasFocus) return;
      switch (key.key) {
        case "ArrowDown":
          key.preventDefault();
          this.isExpanded = true;

          this.focusedIndex += 1;
          this.focusedIndex %= this.options.length;
          this.scrollToFocusedElement();
          break;
        case "ArrowUp":
          key.preventDefault();

          if (this.isActive) {
            this.focusedIndex =
              this.focusedIndex > 0 ? this.focusedIndex - 1 : this.options.length - 1;
            this.focusedIndex %= this.options.length;
            this.scrollToFocusedElement();
          }
          break;
        case "Enter":
          if (this.isActive) {
            this.selectOption(this.options[this.focusedIndex]);
          } else {
            this.isExpanded = true;
          }
          break;
        case "Escape":
          this.isExpanded = false;
          break;
        case "Tab":
          this.isExpanded = false;
          break;
        default:
          const matchingOptions = this.options
            .map((o, i) => ({ text: o.text, index: i }))
            .filter((o) => o.text?.toLowerCase()?.startsWith(key.key?.toLowerCase()))
            .map((o) => o.index);

          if (matchingOptions?.length) {
            this.isExpanded = true;
            const resetIndex =
              this.focusedIndex === matchingOptions[matchingOptions.length - 1] ||
              this.focusedIndex === -1 ||
              !this.options[this.focusedIndex].text
                .toLowerCase()
                .startsWith(key.key.toLowerCase());

            this.focusedIndex = resetIndex
              ? matchingOptions[0]
              : matchingOptions.find((c) => c > this.focusedIndex);
            this.scrollToFocusedElement();
          }
      }
    },
    hoverOption(index) {
      this.focusedIndex = index;
    },
    resetFocused() {
      this.focusedIndex = -1;
    },
    onClick($event) {
      if (!this.$refs.dropdownEl.contains($event.target) && this.isExpanded) {
        this.hasFocus = false;
        this.isExpanded = false;

        this.$emit("blur");
      }
    },
    buildDropdownStyle(){
      if(!this.isExpanded) return;

      const rect = this.$refs.dropdownInput?.getBoundingClientRect();
        this.$nextTick(() => {
          const minimunDropdownSpace = Math.max(150, 
          (this.$refs.dropdown?.children?.[0]?.getBoundingClientRect()?.height || 0) * 1.5);
          if (rect) {
            const distanceToBottom = window.innerHeight - rect.bottom;
            this.dropdownStyle.width = `${rect.width}px`;
            this.dropdownStyle.overflow = 'hidden auto';

            if (distanceToBottom < minimunDropdownSpace && rect.top > distanceToBottom) {
              this.dropdownStyle.bottom = `${rect.height}px`;
              this.dropdownStyle.maxHeight = `${rect.top - 50}px`;
              this.dropdownStyle.borderRadius = '4px 4px 0 0';
            } else {
              this.dropdownStyle.maxHeight = `${distanceToBottom - 60}px`;
              this.dropdownStyle.borderRadius = '0 0 4px 4px';
            }
          }
        });      
    }    
  },
  mounted() {
    window.addEventListener("click", this.onClick);
    window.addEventListener("resize", this.buildDropdownStyle);
  },
  beforeUnmount() {
    window.removeEventListener("click", this.onClick);
    window.addEventListener("resize", this.buildDropdownStyle);
  },
};
</script>

<template>
  <div
    class="dropdown"
    :class="{'dropdown--expanded': isExpanded}"
    tabindex="0"
    @mouseleave="resetFocused"
    @focus="
      hasFocus = true;
      $emit('focus');
    "
    @keydown="checkKeypress"
    @click="onClick($event)"
    ref="dropdownEl"
  >
    <label v-if="label" :for="id">{{ label }}</label>
    <div
      :id="id"
      class="dropdown_border"
      :class="{ 'dropdown--error': errorMessage }"
      ref="dropdownInput"
    >
      <div
        class="dropdown__placeholder"
        :class="{ 'dropdown__selected-value': !showPlaceholder }"
        @click="isExpanded = !isExpanded"
      >
        <slot name="selected" :placeholder="placeholder" :selected="selected">{{
          showPlaceholder ? placeholder : (selectedPrefix ? selectedPrefix + ' ' + selected.text : selected.text )
        }}</slot>
        <img :class="['dropdown__arrow', {'dropdown__arrow--up': isExpanded}]" src="../../static/icons/chevron-grey.svg" alt="">
      </div>
      <transition name="expand" @enter="enter" @leave="leave">
        <div
          v-show="isExpanded"
          ref="dropdown"
          class="dropdown__select"
          :style="dropdownStyle"
        >
          <div v-if="enableSearch" class="dropdown__search">
            <text-input
              :placeholder="searchPlaceholder"
              v-model="searchPhrase"
              ref="searchInput"
            />
            <img
              class="dropdown__search-input-icon"
              src="../../static/icons/search_icon.svg"
            />
          </div>
          <div
            class="dropdown__new-option"
            @click="$emit('add-option', $event)"
            tabindex="-1"
            v-if="addOption"
          >
            <slot name="addOption" v-if="addOption">
              <img
                class="dropdown__new-option-icon"
                src="../../static/icons/plus_icon.svg"
              />
              <div class="dropdown__new-option-caption">
                {{ $globalTexts.global__add_new }}
              </div>
              <div v-if="addOptionLabel" class="dropdown__new-option-label">
                {{ addOptionLabel }}
              </div>
            </slot>
          </div>

          <div
            v-for="(option, index) in filteredOptions"
            :key="index"
            :value="option.value"
            class="dropdown__option"
            :class="[
              isSelected(option.value) ? 'dropdown__option--selected' : '',
              isFocused(index) ? 'dropdown__option--hover' : '',
            ]"
            @click="selectOption(option)"
            @mousemove="hoverOption(index)"
          >
            <slot name="option" :option="option" :isSelected="isSelected(option.value)">{{
              option.text
            }}</slot>
          </div>
        </div>
      </transition>
    </div>

    <error-message :message="errorMessage" />
  </div>
</template>

<style>
.dropdown {
  width: 100%;
  position: relative;
  cursor: pointer;
  user-select: none;
}

.dropdown__search {
  padding: 0.5rem;
}

.dropdown__search-input-icon {
  position: absolute;
  top: 18px;
  right: 16px;
  width: 20px;
  height: 20px;
}

.dropdown_border {
  border-color: var(--color-neutrals-50);
  background-color: var(--color-neutrals-00);
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  width: 100%;
  height: 100%;
}

.dropdown__arrow--up {
  transform: rotate(180deg);
}

.dropdown__arrow--up,
.dropdown__arrow {
  transition: all 0.2s;
}

.dropdown--error {
  border-color: var(--color-text-error);
  color: var(--color-text-error);
  transition: color 0.2s ease, border-color 0.2s ease;
}

.dropdown__select {
  width: 100%;
  z-index: 800;
  position: absolute;
  background-color: var(--color-neutrals-00);
  border: none;
  margin-left: -1px;
  overflow-x: hidden;
  border: 1px solid var(--color-neutrals-50);
  max-height: 50vh;
  box-shadow: 3px 3px 16px rgba(26, 26, 26, 0.12);
}

.dropdown__select:focus {
  outline: none;
  outline-offset: 0;
  overflow: auto;
}

.dropdown__option {
  padding: 10px 13px 0;
  cursor: pointer;
}

.dropdown__new-option {
  display: flex;
  flex-direction: row;
  align-content: center;
  padding: 12px 13px;
  cursor: pointer;
  border-bottom: 1px solid var(--color-neutrals-20);
}

.dropdown__new-option-icon {
  width: 16px;
  height: 16px;
  margin: 0;
  padding-top: 3px;
  padding-right: 2px;
}

.dropdown__new-option-label {
}

.dropdown__new-option-caption {
  padding-left: 0.5rem;
}

.dropdown__option:hover,
.dropdown__option--hover {
  text-decoration: underline;
}

.dropdown__option--selected,
.dropdown__selected-value {
  font-weight: 700;
}

.dropdown__selected-value {
  line-height: 40px;
}

.dropdown__placeholder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 8px 13px;
}

.dropdown__placeholder:focus {
  outline: auto;
}

.dropdown__contracted {
  border-radius: 4px;
}

.expand-enter-active,
.expand-leave-active {
  transition: height 80ms linear;
  overflow: hidden;
}

.expand-enter,
.expand-leave-to {
  height: 0;
}

.dropdown label {
  text-transform: none;
  margin-bottom: 6px;
}
</style>
