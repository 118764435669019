<script>
import {
  fetchPageBuilder,
  navigateKey,
  routeKey,
  OptionsComponent,
} from '@drapejs/core';

import * as kidsSearch from '@/connectors/search';
import {
  isSearchOpenKey,
  searchStringKey,
  searchResultKey,
  channelKey,
} from '@/keys';
import { resolveLinkKey } from '@distancify/drapejs-storyblok';

const quickSearchProductCount = 20;

export default {
  extends: OptionsComponent,
  data: () => ({
    searchResult: null,
    width: 0,
    searchString: '',
    lastSearchString: '',
    cancelPendingRequests: false,
  }),
  inject: {
    navigate: navigateKey,
    route: routeKey,
    resolveLink: resolveLinkKey,
    registerModal: 'registerModal',
    unregisterModal: 'unregisterModal',
    isSearchOpen: isSearchOpenKey,
    $searchStringReactive: searchStringKey,
    $searchResultReactive: searchResultKey,
    channel: channelKey,
  },
  watch: {
    route: {
      handler() {
        this.closeSearch();
      },
      deep: true,
    },
    isSearchOpen(curr) {
      if (curr) {
        this.registerModal('SearchView');
      }
      if (!curr) {
        this.unregisterModal('SearchView');
      }
    },
    searchString() {
      this.$searchStringReactive = this.searchString;
    },
    searchResult() {
      this.$searchResultReactive = this.searchResult;
    },
  },
  computed: {
    rootCategory() {
      return this.$channel?.rootProductsCategory || {};
    },
    isDesktop() {
      return this.width > 768;
    },
  },
  methods: {
    openSearchView() {
      this.searchResult = null;
      this.isSearchOpen = true;
    },
    async applyFacetSuggestion(facet) {
      if (!this.rootCategory?.url) {
        return;
      }
      const query = {
        facets: `${facet.facetId}:${facet.facetValueId}`,
      };

      this.$mitt.emit('clear-search-input');

      await this.goToProductsRootPage(query);
    },
    async performQuickSearch(searchString) {
      this.searchString = searchString;

      if (searchString === '') {
        this.searchResult = null;
        this.isSearchOpen = false;
        return;
      }

      if (!this.rootCategory.id) {
        return;
      }

      const request = fetchPageBuilder(
        this.$route.protocol,
        this.$route.host,
        this.$route.pathname,
        {
          ...this.$route.query,
          search: searchString,
        },
        ''
      );

      request.take = quickSearchProductCount;
      request.categorySystemId = this.rootCategory.id;

      const searchResult = await this.$invoke(
        kidsSearch.commands.performQuickSearch,
        request
      );

      if (searchResult.storySearch) {
        const stories = (searchResult.storySearch.stories || [])
          .map((s) => {
            let c = { ...s };
            c.link = {
              id: s.pageSystemId,
            };
            const link = this.resolveLink(() => c.link);
            c.url = link?.url || '';
            return c;
          })
          .filter((c) => c.url && c.url != this.channel?.rootPath);

        searchResult.pageSearch = {
          ...searchResult.storySearch,
          pages: stories,
        };
      }

      if (this.searchString !== '') {
        this.searchResult = searchResult;
        this.searchResult.searchString = searchString;

        this.isSearchOpen = true;
      }
    },
    async performFullSearch(searchString) {
      if (!this.rootCategory?.url) {
        return;
      }

      const query = { ...this.$route.query };
      delete query.search;

      if (searchString) {
        query.search = searchString.toLowerCase();
      }
      this.$mitt.emit('clear-search-input');

      await this.goToProductsRootPage(query);
    },
    buildUrlQueryString(query) {
      const querySegments = [];
      Object.keys(query).forEach((key) => {
        const value = query[key];
        if (value) {
          querySegments.push(`${key}=${encodeURIComponent(value)}`);
        }
      });

      return querySegments.length > 0 ? `?${querySegments.join('&')}` : '';
    },
    async goToProductsRootPage(query) {
      const querySegments = [];
      Object.keys(query).forEach((key) => {
        const value = query[key];
        if (value) {
          querySegments.push(`${key}=${encodeURIComponent(value)}`);
        }
      });

      const queryString =
        querySegments.length > 0 ? `?${querySegments.join('&')}` : '';
      await this.navigate(`${this.rootCategory.url}${queryString}`);
    },
    setWidth() {
      this.width = window.innerWidth;
    },
    closeSearch() {
      this.isSearchOpen = false;
      this.searchString = '';
    },
  },
  mounted() {
    this.$mitt.on('perform-quick-search', this.performQuickSearch);
    this.$mitt.on('perform-full-search', this.performFullSearch);
    this.$mitt.on('apply-facet-suggestion', this.applyFacetSuggestion);
    this.$mitt.on('close-search', this.closeSearch);
    this.$mitt.on('open-search-view', this.openSearchView);

    this.setWidth();
    window.addEventListener('resize', this.setWidth);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.setWidth);

    this.$mitt.off('perform-quick-search', this.performQuickSearch);
    this.$mitt.off('perform-full-search', this.performFullSearch);
    this.$mitt.off('apply-facet-suggestion', this.applyFacetSuggestion);
    this.$mitt.off('close-search', this.closeSearch);
    this.$mitt.off('open-search-view', this.openSearchView);
  },
  render() {
    return [];
  },
};
</script>
