import { computed, inject, Ref, ref } from 'vue';
import { websiteTextsKey } from '@/keys';

const tokenRegex = /\{{(.+?)\}}/gi;

function replace(textToFormat: string, websiteTexts: Ref<{}>, tokens?: { [key:string]: String | Number }) {
  if (!tokens) {
    tokens = {};
  }

  return textToFormat.replace(tokenRegex, (_, p1: String) => {
    
    if (tokens) {
      let token = tokens[p1.toLowerCase()];
      if (typeof token !== 'undefined') {
        return token.toString();
      }
    }
    
    let token = websiteTexts.value?.[p1.toLowerCase()];
    if (typeof token !== 'undefined') {
      return token.toString();
    }
    
    return `{${p1.toLowerCase()}}`;
  });
}

export default function() {
  const websiteTexts = inject(websiteTextsKey, ref({}));

  return {
    websiteText: (key: string, tokens?: { [key:string]: String | Number }) => computed<string>(() => {
      return replace(websiteTexts.value?.[key] || key, websiteTexts, tokens);
    }),
  }
}