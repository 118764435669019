<script>
export default {
  props: {
    slides: {
      type: Number,
      required: true,
    },
    currentSlide: {
      type: Number,
      required: true,
    },
    size: {
      type: Number,
      default: 10,
    },
    clickSize: {
      type: Number,
      default: 40,
    },
    color: {
      type: String,
      default: '#CCCCCC',
    },
    activeColor: {
      type: String,
      default: '#333333',
    },
    dotSettings: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    slideArray() {
      return new Array(this.slides);
    },
    paddingPx() {
      return (this.clickSize - this.size) / 2;
    },
    cameraStyle() {
      return {
        height: `${this.size}px`,
        width: `${this.size}px`,
      };
    },
  },
  methods: {
    isActive(slide) {
      return slide === this.currentSlide;
    },
    spacerStyle() {
      return {
        padding: `${this.paddingPx}px`,
      };
    },
    currentColor(slide) {
      return this.isActive(slide) ? this.activeColor : this.color;
    },
    dotStyle() {
      return {
        height: `${this.size}px`,
        width: `${this.size}px`,
      };
    },
    useCamera(slide) {
      return this.dotSettings[slide] === 'v';
    },
  },
};
</script>

<template>
  <div class="slide-dots">
    <div
      v-for="(_, slide) in slideArray"
      :key="slide"
      :style="spacerStyle(slide + 1)"
      @click="$emit('change-slide', slide + 1)"
      >
      <div v-if="!useCamera(slide)" class="slide-dots__dot" :class="{'slide-dots__dot--active': isActive(slide + 1)}" :style="dotStyle()"></div>
      <div v-if="useCamera(slide)" class="slide-dots__camera" :style="cameraStyle">
        <svg
          width="15px"
          height="9px"
          viewBox="0 0 15 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="slide-dots__camera-icon"
          >
          <rect
            x="0.5"
            width="10.125"
            height="9"
            rx="1.5"
            :fill="currentColor(slide + 1)"
            />
          <path
            d="M9.5 4.5L13.8324 0.600828C14.0898 0.369159 14.5 0.551837 14.5 0.898145L14.5 8.10186C14.5 8.44816 14.0898 8.63084 13.8324 8.39917L9.5 4.5Z"
            :fill="currentColor(slide + 1)"
            />
        </svg>
      </div>
    </div>
  </div>
</template>

<style>
.slide-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.slide-dots > div {
  pointer-events: all;
}

.slide-dots__dot {
  border-radius: 50%;
  background-color: var(--color-neutrals-50);
}

.slide-dots__dot--active {
  background-color: var(--color-neutrals-80);
}

.slide-dots__camera {
  position: relative;
}

.slide-dots__camera-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
