<script setup>
import { inject, onMounted, ref, computed, watchEffect } from "vue";
import { imageResize } from "@distancify/drapejs-storyblok";
import { routeKey } from "@drapejs/core";

import { getChannelConfiguration } from "@/urlUtils";
import {
  settingsKey,
  activeDesktopFlyoutKey,
  isSearchOpenKey,
  emitterKey,
} from "../../keys";

import DesktopLinks from "./DesktopLinks.vue";
import DesktopFlyout from "./DesktopFlyout.vue";
import SearchViewInput from "@/components/SearchView/SearchViewInput.vue";
import SearchViewDesktop from "@/components/SearchView/SearchViewDesktop.vue";
import SearchViewPhone from "@/components/SearchView/SearchViewPhone.vue";

const settings = inject(settingsKey, {});
const route = inject(routeKey);
const activeDesktopFlyout = inject(activeDesktopFlyoutKey);
const isSearchOpen = inject(isSearchOpenKey);
const mitt = inject(emitterKey);

const channel = getChannelConfiguration(route);
const homeUrl = `/${channel.urlPrefix}`;

const lowRes = computed(() => imageResize(settings.value?.logo, { width: 43 })?.filename);
const highRes = ref(null);
const phoneSearch = ref(false);

onMounted(() => {
  watchEffect(() => {
    highRes.value = imageResize(settings.value?.logo, { width: (window.innerWidth > 1279 ? 55 : 43) })?.filename;
    phoneSearch.value = window.innerWidth < 768;
  });
})
</script>

<template>
  <div
    class="header"
    :class="[
      { 'header--desktop-flyout': activeDesktopFlyout.uid },
      { 'header--desktop-search': isSearchOpen },
    ]"
  >
    <div class="header__margin">
      <router-link :to="homeUrl">
        <img v-if="lowRes" :srcset="highRes" :src="lowRes" class="header__logo" />
      </router-link>
      <DesktopLinks class="header__desktop-links" />
      <div class="header__search">
        <div class="header__desktop-search">
          <SearchViewInput />
        </div>
        <div class="header__phone-search-wrapper">
          <div class="header__phone-search" @click="mitt.emit('open-search-view')">
            <img
              class="header__phone-search-icon"
              src="../../static/icons/search_icon.svg"
            />
            <div class="header__phone-search-text">
              {{
                $globalTexts.global__search__placeholder || "global__search__placeholder"
              }}
            </div>
          </div>
          <div v-if="phoneSearch" class="header__phone-search-view">
            <SearchViewPhone />
          </div>
        </div>
      </div>
      <HeaderActions />
    </div>
  </div>

  <div v-for="item in settings?.mainMenu" :key="item._uid">
    <DesktopFlyout v-if="item.component === 'MenuSection'" :item="item" />
  </div>

  <SearchViewDesktop class="header__desktop-search" />
</template>

<style>
.header {
  background-color: var(--color-neutrals-00);
  position: relative;
  z-index: 10;
}

.header--desktop-flyout,
.header--desktop-search {
  border-bottom: 1px solid var(--color-neutrals-40);
}

.header__margin {
  max-width: 1440px;
  margin: auto;
  display: flex;
}

.header__logo {
  display: block;
  object-fit: contain;
  object-position: center left;

  @media (max-width: 767px) {
    margin: 8px 0 8px 10px;
    width: 43px;
  }
  @media (min-width: 768px) and (max-width: 1279px) {
    width: 43px;
  }
  @media (min-width: 768px) {
    margin: 8px 0 8px 30px;
  }

  @media (min-width: 1280px) {
    width: 55px;
  }
}

.header__search {
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.header__desktop-links {
  @media (max-width: 1279px) {
    display: none;
  }
}

.header__desktop-search {
  @media (max-width: 767px) {
    display: none;
  }
}

.header__phone-search-wrapper {
  margin-left: 1rem;
}

.header__phone-search {
  display: flex;
  cursor: pointer;

  @media (min-width: 768px) {
    display: none;
  }
}

.header__phone-search-icon {
  width: 20px;
  height: 20px;
}

.header__phone-search-text {
  font-family: "ProximaNova-Regular";
  font-size: 14px;
  padding-left: 0.25rem;
}

@media (min-width: 768px) {
  .header__phone-search-view {
    display: none;
  }
}
</style>
