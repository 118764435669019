import { inject, ref, ssrContextKey } from 'vue';
import { Emitter } from 'mitt';

import { pageKey, routeKey, invokeKey, Route, navigateKey } from "@drapejs/core";
import {
  settingsKey,
  Settings,
  replaceTokensKey,
  emitterKey,
  cartKey,
  redirectToLoginPageKey,
  channelKey,
} from '@/keys';
import { environmentKey, EnvironmentOptions } from '@/EnvironmentOptions';
import { resolveLinkKey } from '@distancify/drapejs-storyblok';
import { useGA4 } from '@distancify-storefront/tracking-gtm';
import { injectionKeys as litiumKeys } from '@distancify/drapejs-litium';

export default function () {
  const cart = inject(cartKey, ref<any>(<any>{}));
  const channel = inject(channelKey, ref<any>(<any>{}));

  function formatPrice(
    value: number,
    decimals?: number,
    locale?: string,
    currencyId?: string
  ) {
    if (!decimals) {
      decimals = 2;
    }
    if (value !== 0 && !value) {
      return "";
    }
    if (!cart?.value) {
      return value;
    }
    return new Intl.NumberFormat(locale || channel.value?.locale || "sv-SE", {
      style: "currency",
      currency: currencyId || cart.value.currency?.id || "SEK",
      maximumFractionDigits: decimals,
      minimumFractionDigits: 0,
    }).format(value);
  }


  return {
    page: inject(pageKey, ref()),
    ssrContext: inject(ssrContextKey, <any>{}),
    settings: inject(settingsKey, ref<Settings>(<any>{})),
    channel,
    route: inject(routeKey, <Route>(<any>{})),
    replaceTokens: inject(replaceTokensKey, () => ''),
    mitt: inject(emitterKey, <Emitter<any>>(<any>{})),
    resolveLink: inject(resolveLinkKey, () => <any>null),
    invoke: inject(invokeKey, () => Promise.resolve()),
    navigate: inject(navigateKey, () => Promise.resolve()),
    cart,
    toLitiumMediaUrl: inject(litiumKeys.toLitiumMediaUrlKey, () => ''),
    formatPrice,
    ga4: useGA4({
      useLegacyEnhancedECommerce: true,
      itemEnricher: (item) => {
        item['google_business_vertical'] = 'retail';
        return item;
      },
    }),
    environmentOptions: inject(environmentKey, {} as EnvironmentOptions),
    navigateToLoginPage: inject(redirectToLoginPageKey, (query: any) =>
      Promise.resolve()
    ),
  };
}
