
import {
  defineComponent,
  ssrContextKey,
  inject,
} from "vue";
import { fetchDataSourceKey } from "@distancify/drapejs-storyblok";

import {
  subscribeKey,
  getItemKey,
  setItemKey,
  invokeKey,
} from "@drapejs/core";


import LayoutRoot from "./layouts/LayoutRoot.vue";

export default defineComponent({
  name: "App",
  components: {
    LayoutRoot,
  },
  setup() {
    const getItem = inject(getItemKey);
    const setItem = inject(setItemKey);

    if(typeof window !== 'undefined') {
      trySaveGa4ClientId();
    }

    async function trySaveGa4ClientId() {
      var cookies = getCookies();
      var gaCookie = cookies?.['_ga'];
      if(!gaCookie) {
        return;
      }

      let litiumOptions = await (<any>getItem)('__litium_options') || {};

      litiumOptions.additionalHeaders = {
        ...litiumOptions.additionalHeaders,
        'LH-GA4ClientId' : gaCookie
      };

      await (<any>setItem)('__litium_options', litiumOptions);
    }

    function getCookies() {
      const REGEXP = /([\w\.]+)\s*=\s*(?:"((?:\\"|[^"])*)"|(.*?))\s*(?:[;,]|$)/g;
      let cookies = {};
      let match;
      while ((match = REGEXP.exec(document.cookie)) !== null) {
        let value = match[2] || match[3];
        cookies[match[1]] = decodeURIComponent(value);
      }
      return cookies;
    }
  },
});
