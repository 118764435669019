<script>
import { inject, computed } from 'vue';

import CartItem from "./CartItem.vue";
import Currency from "../Currency.vue";
import PaymentMethods from "../PaymentMethods.vue";
import { settingsKey } from '@/keys';
import useContext from '@/composables/useContext';
import { resolveLinkKey } from '@distancify/drapejs-storyblok'
import DrawerLayer from '@distancify-storefront/ui-drawer-layer';

export default {
  components: {
    CartItem,
    Currency,
    PaymentMethods,
    DrawerLayer,
  },
  data() {
    return {
      isQuickCartOpened: false,
    };
  },
  inject: {
    settings: settingsKey,
    resolveLink: resolveLinkKey
  },
  provide() {
    return {
      //Dummy provide in order to surpress Vue warnings
      $isDisabledReactive: false,
    };
  },
  computed: {
    titleText() {
      return this.$globalTexts.cart__title || "cart__title";
    },
    emptyCartText() {
      return (
        this.$globalTexts.global__label_empty_cart || "global__label_empty_cart"
      );
    },
    toCheckout() {
      return this.$globalTexts.cart__to_checkout || "cart__to_checkout";
    },
    rows() {
      return this.$cart?.rows || [];
    },
    cartIsEmpty() {
      return this.$cart?.isEmpty || false;
    },
    totalLabel() {
      return this.$globalTexts.cart__total || "cart__total";
    },
    freightCostLabel() {
      return this.$globalTexts.cart__freight_cost || "cart__freight_cost";
    },
    freightCost() {
      if (!this.$cart?.totalDeliveryCost) {
        return "";
      }
      return this.$formatPrice(this.$cart?.totalDeliveryCost);
    },
    total() {
      return this.$formatPrice(this.$cart?.grandTotal || 0);
    },
    fulfillmentText() {
      return this.$channel?.cartFulfillmentText || "";
    },
    checkoutUrl(){
      return this.resolveLink(() => this.settings?.checkoutLink)?.url || "#";
    }    
  },
  watch: {
    '$route.pathname'() {
      this.closeCart();
    },
  },
  methods: {
    closeCart() {
      this.isQuickCartOpened = false;
    },
    openCart() {
      this.isQuickCartOpened = true;
    },
    onCheckoutClick() {
      this.closeCart();
      try {
        localStorage.setItem("checkoutPreviousRoute", this.$route.fullPath);
      } catch (e) {}
    },
  },
  mounted() {
    this.$mitt.on('openQuickCart', this.openCart);
  },
  beforeUnmount() {
    this.$mitt.off('openQuickCart', this.openCart);
  }
};
</script>

<template>
  <DrawerLayer v-model="isQuickCartOpened" ref="cartDrawer">
    <div class="quick-cart">
      <h2
        class="quick-cart__title"
        :class="{ 'quick-cart__title-empty--phone': cartIsEmpty }"
      >
        {{ titleText }}
      </h2>

      <div v-if="cartIsEmpty" class="quick-cart__empty">
        {{ emptyCartText }}
      </div>

      <div v-if="!cartIsEmpty" class="quick-cart__list-wrapper">
        <div class="quick-cart__list">
          <cart-item v-for="row in rows" :key="row.id" :row="row" />
        </div>
      </div>

      <div v-if="!cartIsEmpty" class="quick-cart__summary">
        <div v-if="freightCost" class="quick-cart__freight-cost">
          <div>{{ freightCostLabel }}</div>
          <div>{{ freightCost }}</div>
        </div>
        <div class="quick-cart__order-total">
          <div>{{ totalLabel }}</div>
          <div>{{ total }}</div>
        </div>
        <div class="quick-cart__to-checkout" v-if="checkoutUrl">
          <a
            :href="checkoutUrl"
            class="primary-button quick-cart__to-checkout-btn"
            @click.native="onCheckoutClick()"
          >
            {{ toCheckout }}
          </a>
        </div>
        <div class="quick-cart__freight-info">
          {{ fulfillmentText }}
        </div>
        <payment-methods />
      </div>
    </div>
  </DrawerLayer>
</template>

<style>
.quick-cart {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0 30px;
  background-color: var(--color-neutrals-00);

  @media (max-width: 767px) {
    padding: 0 20px;
  }
}

.quick-cart__title {
  font-weight: normal;
  justify-content: center;
  margin: 0;
  font-size: 18px;
  color: var(--color-main-black);
  font-family: "ProximaNova-Bold";
  width: 100%;
}

.quick-cart__empty {
  padding-top: 3rem;
  text-align: center;
  font-size: 22px;
  color: var(--color-main-black);
  font-family: "ProximaNova-Bold";
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 4rem);
}

.quick-cart__list-wrapper {
  overflow-y: auto;
  max-height: 100%;
}

.quick-cart__summary {
  margin-top: auto;
  background-color: var(--color-neutrals-00);
}

.quick-cart__to-checkout {
  padding: 0.75rem 0;
}

a.quick-cart__to-checkout-btn {
  margin: 0;
  font-size: 12px;
  width: 100%;
  text-decoration: none;
}

.quick-cart__freight-cost {
  display: flex;
  justify-content: space-between;
  color: var(--color-main-black);
  padding: 0;
  font-size: 14px;
}

.quick-cart__order-total {
  display: flex;
  padding: 0.75rem 0 0;
  justify-content: space-between;
  color: var(--color-main-black);
  font-size: 24px;
  line-height: 28px;
  font-family: "ProximaNova-Bold";
}

.quick-cart__freight-info {
  color: var(--color-neutrals-80);
  font-size: 14px;
  text-align: center;
}
</style>
