<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    modelValue: {
      type: [Boolean, String, Number],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
};
</script>

<template>
  <label class="checkbox">
    <span class="checkbox__label" v-html="$replaceTokens(text)" />
    <input
      class="checkbox__input"
      type="checkbox"
      :checked="modelValue"
      @input="$emit('update:modelValue', $event.target.checked)"
      :disabled="disabled"
    />
    <span class="checkbox__checkmark">
      <svg
        class="checkbox__checkmark-icon"
        width="12"
        height="12"
        viewBox="0 0 20 14"
        fill="#333333"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line
          x1="0.883883"
          y1="6.22945"
          x2="5.42945"
          y2="10.775"
          stroke="#333333"
          stroke-width="1.5"
          stroke-linecap="round"
        />
        <line
          x1="5.42944"
          y1="10.775"
          x2="15.4045"
          y2="0.799956"
          stroke="#333333"
          stroke-width="1.5"
          stroke-linecap="round"
        />
      </svg>
    </span>
  </label>
</template>

<style>
.checkbox {
  display: flex;
  position: relative;
  padding-left: 1.75rem;
  height: 22px;
  width: 22px;
  align-items: center;
  cursor: pointer;
  user-select: none;
  font-weight: 400;
}

label.checkbox {
  text-transform: none;
}

.checkbox__label {
  font-size: 14px;
  white-space: nowrap;
  color: var(--color-main-black);
  padding: 0 0 3px 3px;
  font-family: "ProximaNova-Regular";
}

.checkbox__input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

.checkbox__checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background-color: rgba(184, 204, 214, 0.25);
  border-radius: 2px;
  box-shadow: inset 1px 2px 2px rgba(109, 109, 142, 0.15);
}

.checkbox__input:checked ~ .checkbox__checkmark {
  border-color: #333333;
}

.checkbox__checkmark-icon {
  display: none;

  fill: #333333;
  height: 100%;
  width: 100%;
  padding: 2px 0 0 2px;
}
.checkbox__checkmark-path {
  fill: #333333;
}
.checkbox__input:checked ~ .checkbox__checkmark .checkbox__checkmark-icon {
  display: inline;
}
</style>
