
import mitt from "mitt";
import useScreenFreeze from "@/composables/useScreenFreeze";

import {
  onServerPrefetch,
  ref,
  inject,
  provide,
  computed,
  onMounted,
  watchEffect,
} from "vue";

import {
  fetchPage,
  getServerItemKey,
  subscribeKey,
  invokeKey,
  getItemKey,
  bind,
  navigateKey,
} from "@drapejs/core";

import {
  useDataSources,
  configureResolveLinkRewriter,
  configureLanguageResolver,
  isInEditorKey,
} from "@distancify/drapejs-storyblok";

import useContext from "@/composables/useContext";
import { usePageView } from "@distancify-storefront/tracking-gtm";
import useShoppingPreference from "@/composables/useShoppingPreference";

import {
  fetchPageBuildWithStoryblokFolderPrefix,
  getChannelConfiguration,
} from "../urlUtils";

import {
  buildQueryParams,
  buildUrlPathWithQuery,
  getPrefferedCountryCookieValue,
  getPrefferedLanguageCookieValue
} from "../utils";

import {
  settingsKey,
  cartKey,
  websiteTextsKey,
  channelKey,
  countrySwitcherKey,
  metaProductKey,
  emitterKey,
  userKey,
  redirectToLoginPageKey,
  userInfoKey,
} from "../keys";

import SimpleLayout from "./SimpleLayout.vue";
import AuthRequiredLayout from "./AuthRequiredLayout.vue";

let openModals: string[] = [];
const { freeze, unfreeze } = useScreenFreeze();

export default {
  components: {
    SimpleLayout,
    AuthRequiredLayout,
  },
  setup() {
    const { page, route, ssrContext, resolveLink, environmentOptions } = useContext();

    const { setLanguage } = useShoppingPreference();

    if (route.query.country) {
      onServerPrefetch(async () => {
        await setDesiredLanguage();
      });
      return;
    }

    const getServerItem = inject(getServerItemKey, () => null);
    const getItem = inject(getItemKey, () => <any>null);
    const subscribe = inject(subscribeKey, () => <any>null);
    const invoke = inject(invokeKey, () => <any>null);
    const navigate = inject(navigateKey, async () => <any>null);
    const isInEditor = inject(isInEditorKey, ref(false));

    const settingsFetchPage = fetchPageBuildWithStoryblokFolderPrefix(route, `/settings`);
    const {
      fetchDataSource,
      getDataSource,
      getServerDataSource,
      subscribeDataSource,
    } = useDataSources();

    const channelConfig = getChannelConfiguration(route);

    if (!channelConfig) {
      return {
        layout: "SimpleLayout",
      };
    }

    // const textsDimension = channelConfig.urlPrefix
    //   ? `${channelConfig.urlPrefix}_${channelConfig?.language || ""}`
    //   : channelConfig?.language || "";
    const textsDimension = channelConfig?.language || "";

    const cart = bind("__cart");
    provide(cartKey, cart);

    const user: any = ref({
      isAuthenticated: false,
      init: false
    });
    provide(userKey, user);

    const settings = ref(getServerItem(settingsFetchPage.cacheKey));
    provide(settingsKey, settings);

    const texts = ref(getServerDataSource("websitetexts"));
    provide(websiteTextsKey, texts);

    const channel = ref(getServerItem("__channel"));
    provide(channelKey, channel);

    const countrySwitcher = ref(getItem("__countrySwitcher"));
    provide(countrySwitcherKey, countrySwitcher);

    const metaProduct = ref();
    provide(metaProductKey, metaProduct);

    const emitter = mitt();
    provide(emitterKey, emitter);

    const userInfo = ref({});
    provide(userInfoKey, userInfo);

    provide(redirectToLoginPageKey, redirectToLoginPage);

    onServerPrefetch(async () => {
      channel.value = await getItem("__channel");
      settings.value = await invoke(fetchPage, settingsFetchPage);
      texts.value = await fetchDataSource("websitetexts", textsDimension);

      metaProduct.value = {
        uspMap: channel.value?.metaProduct?.fieldsJson?.USPMap?.reduce((acc, curr) => {
          acc.set(curr.USP.value, curr);
          return acc;
        }, new Map()),
        certificationMap: channel.value?.metaProduct?.fieldsJson?.CertificationMap?.reduce(
          (acc, curr) => {
            acc.set(curr.Certification.value, curr);
            return acc;
          },
          new Map()
        ),
        safetyTexts: channel.value?.metaProduct?.fieldsJson?.SafetyTexts,
      };
    });

    usePageView(async () => {
      return {
        channel: channel.value?.systemId,
      };
    });

    watchEffect(() => {
      metaProduct.value = {
        uspMap: channel.value?.metaProduct?.fieldsJson?.USPMap?.reduce((acc, curr) => {
          acc.set(curr.USP.value, curr);
          return acc;
        }, new Map()),
        certificationMap: channel.value?.metaProduct?.fieldsJson?.CertificationMap?.reduce(
          (acc, curr) => {
            acc.set(curr.Certification.value, curr);
            return acc;
          },
          new Map()
        ),
        safetyTexts: channel.value?.metaProduct?.fieldsJson?.SafetyTexts,
      };
    });

    subscribe(settingsFetchPage.cacheKey, (val) => {
      settings.value = val;
    });

    if (!settings.value) {
      onMounted(async () => {
        settings.value = await getItem(settingsFetchPage.cacheKey);
        settings.value = invoke(fetchPage, settingsFetchPage);
      });
    }

    onMounted(async () => {
      await setLanguage({
        countryId: getPrefferedCountryCookieValue() || "",
        languageId: getPrefferedLanguageCookieValue() || "",
        ensureCartIntegrity: true
      });

      subscribe(
        "__userInfo",
        (data) => {
          if (data) {
            userInfo.value = data;
          }
        },
        { immediate: true }
      );

      subscribe("__user", (data) => {
        if (data) {
          user.value = {
            ...data,
            init: true
          };
        }
      },
      { immediate: true });

      subscribe("__litium", (data) => {
        const isAuthenticated = data?.uid && data?.uid != "NotAuthenticated";
        if (!isAuthenticated) {
          user.value = {
            isAuthenticated: false,
            init: true
          };
        }
      },
      { immediate: true });

      subscribe("__channel", (val) => {
        channel.value = val;
      });

      subscribe(
        "__countrySwitcher",
        (val) => {
          countrySwitcher.value = val;
        },
        { immediate: true }
      );
      if (!countrySwitcher.value) {
        countrySwitcher.value = await getItem("__countrySwitcher");
      }

      if (!channel.value) {
        channel.value = await getItem("__channel");
      }

      const litium = await getItem("__litium");
      const isAuthenticated =
        litium?.uid && litium.uid != "NotAuthenticated" ? true : false;

      if (isAuthenticated) {
        user.value = (await getItem("__user")) || {
          isAuthenticated,
        };
      }

      watchEffect(async () => {
        if (
          environmentOptions.requireLogin &&
          settings.value.authLink &&
          !user.value.isAuthenticated && 
          user.value.init &&
          page.value.component &&
          page.value?.component !== "Auth"
        ) {
          await redirectToLoginPage();
        }
      });

      document.documentElement.style.setProperty(
        "--vh",
        `${window.innerHeight * 0.01}px`
      );
    });

    subscribeDataSource("websitetexts", (val) => {
      texts.value = val;
      exposeWebsiteTexts();
    });

    if (!texts.value) {
      onMounted(async () => {
        texts.value = await getDataSource("websitetexts");
        texts.value = await fetchDataSource("websitetexts", textsDimension);
        exposeWebsiteTexts();
      });
    }

    configureResolveLinkRewriter((link) => {
      if (link.isExternal) {
        return link;
      }

      const pattern = "/" + environmentOptions.contentRootPath;
      if (pattern && link.url?.indexOf(pattern) === 0) {
        link.url = link.url.substring(pattern.length);
      } else {
        return link;
      }

      const channelConfig = getChannelConfiguration(route);
      if (channelConfig?.urlPrefix) {
        link.url = `/${channelConfig.urlPrefix}${link.url}`;
      }

      return link;
    });

    configureLanguageResolver(() => page.value.__sb_lang);

    return {
      layout: computed(() => {
        if (
          route.component === "page-auth" ||
          route.component === "page-checkout" ||
          route.component === "page-wholesale-checkout" ||
          route.component === "page-external-payment"
        ) {
          return "SimpleLayout";
        } else if (environmentOptions.requireLogin && !user.value.isAuthenticated && isInEditor?.value == false) {
          /*
            We need a layout with a router view in order for the navigate function 
            to push the url properly. At the same time, we don't want to display
            anything beside the login form if the user is not authenticated
          */
          return "AuthRequiredLayout";
        }
        return "StandardLayout";
      }),
      texts,
      privacyLink: resolveLink(() => settings.value?.privacyLink),
    };

    function exposeWebsiteTexts() {
      if (typeof window == "undefined") {
        return;
      }

      window["__datasource_websitetexts"] = texts.value;
    }

    async function redirectToLoginPage(query = null) {
      const search = query || route?.query || {};

      const completeQuery = {
        ...search,
      };

      if (completeQuery.redirect === undefined) {
        completeQuery.redirect = encodeURIComponent(route?.pathname || "");
      }

      if (channel.value?.rootPath && settings.value?.authLink?.cached_url) {
        const loginSlugSegments = settings.value.authLink.cached_url.split("/") || [];
        if (loginSlugSegments.length > 0) {
          const loginUrl = buildUrlPathWithQuery(
            channel.value?.rootPath + loginSlugSegments[loginSlugSegments.length - 1],
            completeQuery
          );
          await navigate(loginUrl);
        } else {
          console.warn("Failed to redirect to login url due to missing data");
        }
      }
    }

    async function setDesiredLanguage() {
      try {
        await setLanguage({
          countryId: route.query.country || "",
          languageId: route.query.language || ""
        });
      } finally {
        const query = { ...route.query };
        delete query.country;
        delete query.language;

        let params = buildQueryParams(query) || "";
        if (params) {
          params = `?${params}`;
        }

        let host = ssrContext.req.hostname;
        if (host === "localhost") {
          host += `:${ssrContext.req.host.split(":")[1]}`;
        }

        const url = `//${host}${route.pathname}${params}`;
        ssrContext.redirectUrl = url;
      }
    }
  },
  provide() {
    return {
      registerModal: (key: string) => {
        openModals = openModals.filter((k) => k !== key);
        openModals.push(key);
        freeze(key);
      },
      unregisterModal: (key: string) => {
        openModals = openModals.filter((k) => k !== key);
        unfreeze(key);
      },
    };
  },
};
