import { inject, computed, ref } from 'vue';

import * as userConnector from '../connectors/user';

import { setPrefferedCountryCookie, setPrefferedLanguageCookie } from '@/utils';
import { countrySwitcherKey } from '@/keys';

import useContext from '@/composables/useContext';
import useUser from '@/composables/useUser';
import useChannel from '@/composables/useChannel';
import useWebsiteTexts from '@/composables/useWebsiteTexts';
import useExecuteCommand from '@/composables/useExecuteCommand';

export default function () {
  const { cart } = useContext();
  const { channel } = useChannel();
  const { websiteText } = useWebsiteTexts();
  const { executeCommand } = useExecuteCommand();
  const { purchasingCountry } = useUser();

  const countrySwitcher = inject(countrySwitcherKey, ref(null));

  const availableCountries = computed(() => {
    return countrySwitcher?.value?.availableCountries;
  });

  const geoIpCountryCode = computed(() => countrySwitcher?.value?.geoIpCountryCode || '');

  const activeCountryOption = computed(() => {
    const cartCountryId = cart?.value?.countryId;
    const channelId = channel.value?.systemId;

    return (availableCountries?.value || []).find(
      (c) => c.countryId == cartCountryId && c.channelSystemId == channelId
    );
  });

  const countryOptions = computed(() => {
    const channels = [...(availableCountries?.value || [])];
    channels.forEach((c) => {
      (c.value = c.languageId + '_' + c.countryId), (c.text = (c.name || '').trim());
    });
    return channels;
  });

  const changeCountryError = computed(() => websiteText('global__change_country_error').value);

  const availableLanguages = computed(() => {
    const availableCountries = countrySwitcher.value?.availableCountries || [];
    const filtredByPurchasingCountry = availableCountries.filter((e) => e.countryId === purchasingCountry.value);

    if(filtredByPurchasingCountry.length > 0){
      return filtredByPurchasingCountry;
    }

    const filterdByCartCountry = availableCountries.filter((e) => e.countryId === cart.value?.countryId);
    return filterdByCartCountry;
  });

  const canChangeLanguage = computed(() => {
    return (availableLanguages?.value || []).length > 0;
  });

  const activeLanguageOption = computed(() => {
    const cartCountryId = cart?.value?.countryId;
    const channelId = channel.value?.systemId;

    return (availableLanguages?.value || []).find(
      (c) => c.countryId === cartCountryId && c.channelSystemId === channelId
    );
  });

  const languageOptions = computed(() => {
    const channels = [...(availableLanguages?.value || [])];
    channels.forEach((c) => {
      c.value = c.languageId + '_' + c.countryId;
      c.text = (c.languageName || '').trim();
    });
    return channels;
  });

  const changeLanguageError = computed(() => websiteText('global__change_language_error').value);

  async function setLanguage({ languageId, countryId, ensureCartIntegrity = false }) {
    try {
      const result = await executeCommand(userConnector.commands.setLanguage, {
        languageId,
        countryId,
        ensureCartIntegrity,
      });
      if (countryId) {
        setPrefferedCountryCookie(countryId);
      }
      if (languageId) {
        setPrefferedLanguageCookie(languageId);
      }
      return result;
    } catch (err) {}
  }

  function deconstructLanguageModel(languageModel: string) {
    const split = languageModel.split('_');
    return {
      languageId: split[0],
      countryId: split[1],
    };
  }

  return {
    availableCountries,
    geoIpCountryCode,
    activeCountryOption,
    countryOptions,
    changeCountryError,
    availableLanguages,
    activeLanguageOption,
    languageOptions,
    setLanguage,
    changeLanguageError,
    canChangeLanguage,
    deconstructLanguageModel,
  };
}
