<script>
import { fetchPageBuilder } from "@drapejs/core";
import QuantityInput from "../QuantityInput.vue";
import Checkbox from "../forms/Checkbox.vue";
import { sales } from "@distancify/drapejs-litium";

export default {
  components: {
    QuantityInput,
    Checkbox,
  },
  props: {
    row: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    inputQuantity: "",
    isMounted: false,
    error: "",
  }),
  inject: {
    $isDisabledReactive: "$isDisabledReactive" || false,
  },
  computed: {
    isDisabled() {
      return this.$isDisabledReactive;
    },
    productName() {
      return this.row.variant?.displayName || this.row.variantName || "";
    },
    concept() {
      return this.row?.variant?.concept || "";
    },
    hasCampaignPrice() {
      return (
        this.row.unitPrice > 0 && this.row.unitPrice < this.row.unitListPrice
      );
    },
    campaignPrice() {
      return this.row.unitPrice * this.quantity;
    },
    unitListPrice() {
      return this.row.unitListPrice * this.quantity;
    },
    campaignDecimals() {
      return this.$cart.campaignDecimals || 0;
    },
    quantity() {
      return this.row.quantity;
    },
    imageAlt() {
      return this.row?.variant?.images?.[0]?.alt || "";
    },
    productUrl() {
      return this.row?.variant?.url || "";
    },
    isFreeGift() {
      return this.row.isFreeGift;
    },
  },
  methods: {
    getProductImageUrl() {
      const imageHeight =
        this.$refs?.imageContainer?.getBoundingClientRect()?.height ?? 300;

      const imageId = this.row?.variant?.images?.[0]?.imageId;
      if (!imageId) return "";

      return this.$toLitiumMediaUrl(imageId, { maxHeight: imageHeight }) || "";
    },
    async onRemove() {
      if (this.isDisabled) {
        return;
      }
      await this.change({ rowId: this.row.id, quantity: 0 });
    },
    async onChange(quantity) {
      if (this.isDisabled) {
        return;
      }
      await this.change({ rowId: this.row.id, quantity });
    },
    async change(row) {
      const request = this.buildRequest();
      request.rowId = row.rowId;
      request.quantity = row.quantity;
      request.url = `${request.protocol}//${request.host}${request.path}`;

      const result = await this.$invoke(sales.setRowQuantity, request);

      const { error } = result;
      if (error === "NOT_ENOUGH_STOCK") {
        this.error = this.$replaceTokens(
          this.$globalTexts.cart__available_stock,
          {
            amount: this.quantity,
          }
        );
      } else {
        this.error = "";
      }

      if (this.$refs.quantityInput) {
        this.$refs.quantityInput.setAmount(this.quantity);
      }
    },
    buildRequest() {
      return fetchPageBuilder(
        this.$route.protocol,
        this.$route.host,
        this.$route.pathname,
        {
          ...this.$route.query,
        },
        ""
      );
    },
  },
  mounted() {
    this.isMounted = true;
  },
};
</script>

<template>
  <div class="cart-item">
    <div class="cart-item__info">
      <router-link :to="productUrl" class="cart-item__thumbnail-container">
        <div class="cart-item__variant-image-overlay"></div>
        <img
          v-if="isMounted"
          :src="getProductImageUrl()"
          :alt="imageAlt"
          loading="lazy"
          class="cart-item__variant-image"
        />
      </router-link>

      <div class="cart-item__details">
        <div>
          <div class="cart-item__product_concept">
            {{ concept }}
          </div>
          <div class="cart-item__product-name">
            {{ productName }}
          </div>
        </div>

        <div class="cart-item__pricing">
          <quantity-input
            :quantity="quantity"
            :error="error"
            @update:quantity="onChange"
            ref="quantityInput"
          />
          <div
            v-if="!hasCampaignPrice && !isFreeGift"
            class="cart-item__pricing-item cart-item__subtotal"
          >
            <div>{{ $formatPrice(unitListPrice) }}</div>
          </div>
          <div
            v-if="hasCampaignPrice || isFreeGift"
            class="cart-item__pricing-item cart-item__subtotal"
          >
            <div class="cart-item__campaign-price">
              {{ $formatPrice(campaignPrice) }}
            </div>
            <div class="cart-item__unit-list-price">
              {{ $formatPrice(unitListPrice) }}
            </div>
          </div>
        </div>
      </div>
      <div class="cart-item__toolbar">
        <img
          class="cart-item__remove"
          :class="{ 'cart-item__remove--disabled': isDisabled }"
          src="../../static/icons/trash.svg"
          @click="onRemove()"
        />
      </div>
    </div>
  </div>
</template>

<style>
.cart-item {
  border-top: 1px solid var(--color-neutrals-40);
  margin: 0 0 0.25rem;
}

.cart-item__remove {
  cursor: pointer;
}

.cart-item__quantity {
  border-top: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
}

.cart-item__quantity-toggle {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0.45rem 0;
  cursor: pointer;
}

.cart-item__quantity-amount-toggle {
  display: flex;
  align-items: center;
}

.cart-item__quantity-caption,
.cart-item__quantity-amount {
  font-size: 14px;
  line-height: 16px;
  color: #181516;
}

.cart-item__quantity-amount {
  padding-right: 0.5rem;
}

.cart-item__pricing {
  display: flex;
  justify-content: space-between;
  font-family: "ProximaNova-Bold";
}

.cart-item__pricing-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: var(--color-main-black);
}

.cart-item__campaign-price {
  padding-right: 0.5rem;
  color: var(--color-sale);
}

.cart-item__unit-list-price {
  color: var(--color-text-light);
  text-decoration: line-through;
}

.cart-item__subtotal {
  font-weight: 700;
  color: var(--color-main-black);
}

.cart-item__toolbar {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 0.75rem;
}

.cart-item__availability {
  font-size: 13px;
  color: var(--color-neutrals-80);
  line-height: 18px;
}

.cart-item__info {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  padding: 1rem 0 1rem;
  position: relative;
}

.cart-item__thumbnail-container {
  position: relative;
  width: 100px;
  height: 130px;
  user-select: none;
}

.cart-item__thumbnail {
  padding-top: 130%;
}

.cart-item__image-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.8;
  background-color: #efefef;
  mix-blend-mode: darken;
}

.cart-item__variant-image {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  margin: auto;
}

.cart-item__variant-image-overlay {
  z-index: 10;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.8;
  background-color: #efefef;
  mix-blend-mode: darken;
}

.cart-item__details {
  width: 100%;
  padding: 0 0 0 0.75rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  margin-right: -1rem;
}

.cart-item__product-name {
  font-family: "ProximaNova-Bold";
  color: var(--color-main-black);
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
  vertical-align: top;
}

.cart-item__product_concept {
  font-size: 12px;
  line-height: 14px;
  color: var(--color-main-black);
  text-transform: uppercase;
}

.cart-item__product-price {
  margin-right: 0.5rem;
}

.cart-item__product-price-original {
  color: var(--color-gray-1200);
  position: relative;
}

.cart-item__product-price-original::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -1px;
  right: -1px;
  border-top: 2px solid var(--color-gray-1200);
}

.cart-item__article-number,
.cart-item__product-price,
.cart-item__color-size {
  margin: 0.15rem 0;
  font-size: 12px;
}

.cart-item__color-size {
  margin-bottom: 0.5rem;
}

.cart-item__quantity-container {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  flex-wrap: wrap;

  user-select: none;
}

.cart-item__quantity-value,
.cart-item__quantity-decrease,
.cart-item__quantity-increase {
  color: var(--color-gray-1100);
  flex: 1 1 0px;
  padding: 0.5rem;
}

.cart-item__quantity-decrease,
.cart-item__quantity-increase {
  cursor: pointer;
}

.cart-item__quantity-decrease:hover,
.cart-item__quantity-increase:hover {
  color: var(--color-gray-900);
}

.cart-item__delete {
  font-size: 14px;
  cursor: pointer;
  display: block;
  text-decoration: none;
  color: var(--color-gray-700);
  align-self: flex-end;
  border-bottom: 1px solid var(--color-gray-700);
  user-select: none;
}

.cart-item__delete:hover,
.cart-item__delete:focus {
  color: var(--color-gray-900);
  border-color: var(--color-gray-900);
}

.cart-item__giftCard-actions {
  display: flex;
  justify-content: flex-end;
}

.cart-item__remove--disabled {
  cursor: not-allowed;
}

@media (--phone) {
  .cart-item:last-child {
    border-bottom: none;
  }

  .cart-item__quantity-container {
    padding: 0;
  }

  .cart-item__details {
    justify-content: flex-start;
  }

  .cart-item__pricing {
    margin-top: 4px;
    flex-direction: column-reverse;
    flex: 1;
    align-items: flex-start;
  }
}
</style>
