import {
  inject,
  InjectionKey,
  provide,
  ref,
  Ref,
  readonly,
  computed,
  toRaw,
} from 'vue';

import {
  routeKey,
  pageKey,
  navigateKey,
  Route,
  bind,
} from '@drapejs/core';

import useChannel from '@/composables/useChannel';

export interface ProductData extends Record<string,any> {
  variants: ProductVariant[]
}

export interface ProductVariant extends Record<string,any> {
  id: string
}

export const productDataKey: InjectionKey<Ref<ProductData>> = Symbol('productData');
export const selectedVariantKey: InjectionKey<Ref<ProductVariant>> = Symbol('selectedVariant');
export const canAddToCartKey: InjectionKey<Ref<boolean>> = Symbol('canAddToCart');
export const setSelectedVariantKey: InjectionKey<(variant: ProductVariant) => void> = Symbol('setSelectedVariant');
export const getUrlForVariantKey: InjectionKey<(variant: ProductVariant) => string> = Symbol('getUrlForVariant');

export default function () {

  const navigate = inject(navigateKey, () => Promise.resolve());
  const route = inject(routeKey, <Route><any>{});
  const page = inject(pageKey, ref<any>());

  const { channel } = useChannel();

  const productData = bind<ProductData>(() => getProductCacheKey(page.value.articleNumber), resetVariant);

  const selectedVariant = ref<ProductVariant>();
  const canAddToCart = computed(() => {
    const physicalStock = selectedVariant.value?.stock?.currentStock?.amount || 0;
    const totalStockAvailable = selectedVariant.value?.stock?.totalStockAvailable || 0;
    const allowOrderingAgainstIncomingStock = channel.value?.allowOrderingAgainstIncomingStock || false;

    if (!selectedVariant.value) return false;
    if (physicalStock <= 0) {
      return (
        totalStockAvailable > 0 &&
        allowOrderingAgainstIncomingStock
      );
    }
    return true;
  });

  provide(productDataKey, productData);
  provide(selectedVariantKey, readonly(selectedVariant));
  provide(canAddToCartKey, canAddToCart);
  provide(setSelectedVariantKey, setSelectedVariant);
  provide(getUrlForVariantKey, getUrlForVariant);

  return {
    productData,
    selectedVariant,
  };

  function setSelectedVariant(variant: ProductVariant) {
    selectedVariant.value = variant;
    navigate(getUrlForVariant(selectedVariant.value));
  }

  function resetVariant() {
    if (route.query.variant && productData.value?.variants) {
      selectedVariant.value = productData.value.variants.find(r => r.id === route.query.variant);
      if (selectedVariant.value) {
        return;
      }
    }
    if (productData.value?.variants?.length >= 1) {
      selectedVariant.value = productData.value.variants[0];
    }
  }

  function getUrlForVariant(variant: ProductVariant) {
    const query = Object.assign({}, toRaw(route.query));

    if (variant) {
      query.variant = variant.id;
    } else {
      delete query.variant;
    }

    return route.pathname + toQueryString(query);
  }

  function toQueryString(queryParams: { [key: string]: string | null }) {
    const queryItems: string[] = [];
    if (queryParams) {
      for (const key of Object.keys(queryParams).sort()) {
        const value = queryParams[key];
        if (value) {
          queryItems.push(`${key}=${value}`);
        } else {
          queryItems.push(`${key}`);
        }
      }
    }
  
    if (queryItems.length > 0) {
      return `?${queryItems.join('&')}`;
    }
    return '';
  }
}

export function getContext() {
  return {
    productData: inject(productDataKey, ref<ProductData>(<any>{})),
    selectedVariant: inject(selectedVariantKey, ref<ProductVariant>(<any>{})),
    canAddToCart: inject(canAddToCartKey, ref(false)),
    setSelectedVariant: inject(setSelectedVariantKey, () => null),
    getUrlForVariant: inject(getUrlForVariantKey, () => ''),
  }
}

export function getProductCacheKey(articleNumber: string) {
  return `product:${articleNumber}`;
}