import { enUS, sv, nb, fi, de, nl } from "date-fns/locale"

export const dateLocales = {
  "en-US": enUS,
  "en-GB": enUS,
  "sv-SE": sv,
  "nb-NO": nb,
  "fi-FI": fi,
};

export function validateEmail(email: string) {
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(email);
}

export function getParameterByName(name: string, url = window.location.href) {
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function buildQueryParams(query: any) {
  const querySegments: any[] = [];

  for (const parameter in query) {
    const value = query[parameter];
    querySegments.push(`${parameter}=${value}`);
  }

  return querySegments.join("&");
};

export function buildUrlPathWithQuery(path: string, query: any) {
  try {
    const queryParams = buildQueryParams(query);
    return queryParams ? `${path}?${queryParams}` : path;
  }
  catch (err) {
    console.error(err);
    return path;
  }
}

const whitelistedParams = [
  "color",
  "size",
  "action",
  "utm",
  "gclid",
  "fbclid",
  "variant"
];


export function buildChannelSwitchUrlPathWithQuery(path: string, query: any) {
  try {
    const querySegments: any[] = [];
    for (const parameter in query) {
      if (isWhitelistedParameter(parameter)) {
        const value = query[parameter];
        querySegments.push(`${parameter}=${value}`);
      }
    }

    const queryParams = querySegments.join("&");
    return queryParams ? `${path}?${queryParams}` : path;
  }
  catch (err) {
    console.error(err);
    return path;
  }

  function isWhitelistedParameter(parameter: string) {
    return whitelistedParams.find(p => parameter.startsWith(p));
  }
}

export const debounce = (callback, wait) => {
  let timeoutId: number | null = null;
  return (...args) => {
    if (timeoutId !== null) {
      window.clearTimeout(timeoutId);
    }
    timeoutId = window.setTimeout(() => {
      callback.apply(null, args);
    }, wait);
  };
}

export function getPrefferedCountryCookieValue() {
  return getCookies()?.['PrefferedCountry'];
}

export function getPrefferedLanguageCookieValue() {
  return getCookies()?.['PrefferedLanguage'];
}

export function setPrefferedCountryCookie(countryId) {
  setYearlyCookie('PrefferedCountry', countryId);
}

export function setPrefferedLanguageCookie(languageId) {
  setYearlyCookie('PrefferedLanguage', languageId);
}

function setYearlyCookie(cookieName: string, cookieValue : string){
  const aYearFromNow = new Date();
  aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1);

  var dateString = aYearFromNow.toUTCString();
  document.cookie = `${cookieName}=${cookieValue};Path=/;Expires=${dateString};`;  
}

function getCookies() {
  const REGEXP = /([\w\.]+)\s*=\s*(?:"((?:\\"|[^"])*)"|(.*?))\s*(?:[;,]|$)/g;
  let cookies = {};
  let match;
  while ((match = REGEXP.exec(document.cookie)) !== null) {
    let value = match[2] || match[3];
    cookies[match[1]] = decodeURIComponent(value);
  }
  return cookies;
}
