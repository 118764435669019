let scrollY = 0;
const frozenKeys = new Set();

export default function() {
  return {
    freeze,
    unfreeze,
  }
}

function freeze(key: string) {
  if (!window || !document?.body) {
    return;
  }

  if (frozenKeys.size === 0) {
    const scrollbarWidth = window.innerWidth - document.body.offsetWidth;
    scrollY = window.pageYOffset;
    document.body.style.overflow = "hidden";
    document.body.style.marginRight = `${scrollbarWidth.toString()}px`;
  }

  frozenKeys.add(key)
}

function unfreeze(key: string) {
  frozenKeys.delete(key)
  
  if (!window || !document?.body || frozenKeys.size > 0) {
    return;
  }

  document.body.style.overflow = '';
  document.body.style.marginRight = '';
  window.scrollTo(0, scrollY || 0);
}