import { ref } from 'vue';

import * as search from '@/connectors/search';

import useExecuteCommand from '@/composables/useExecuteCommand';
import useWebsiteTexts from '@/composables/useWebsiteTexts';
import useContext from '@/composables/useContext';
import { sales } from '@distancify/drapejs-litium';

export default function () {
  const { executeCommand } = useExecuteCommand();
  const { websiteText } = useWebsiteTexts();
  const { cart, ga4 } = useContext();

  const requestPending = ref(false);

  async function addToCart({ articleNumber, quantity }) {
    try {
      requestPending.value = true;
      const { row, error } = await executeCommand(sales.addToCart, {
        articleNumber,
        quantity,
      });
      if (error !== undefined && error !== 'NONE') {
        switch (error) {
          case 'NOT_ENOUGH_STOCK':
          case 'INVALID_PACK_QUANTITY':
            return { row, error };
          default:
            throw websiteText('cart__add_to_cart_error').value || 'cart__add_to_cart_error';
        }
      }

      if (typeof sessionStorage != 'undefined') {
        sessionStorage.setItem('ResetCheckoutScrollPosition', articleNumber);
      }

      ga4.addToCart(cart.value?.currency?.id, [
        {
          item_id: row.articleNumber,
          item_name: row.variantName,
          price: row.unitListPrice,
          discount: row.unitListPrice - row.unitPrice,
          quantity: 1,
        },
      ]);

      return { row, error };
    } catch (err) {
      throw err;
    } finally {
      requestPending.value = false;
    }
  }

  async function fetchProducts(searchPhrase) {
    try {
      requestPending.value = true;

      const response = await executeCommand(search.commands.getProducts, {
        take: 6,
        searchPhrase,
      });

      return response;
    } catch (err) {
      throw websiteText('cart__fetch_products_error').value || 'cart__fetch_products_error';
    } finally {
      requestPending.value = false;
    }
  }

  return {
    addToCart,
    fetchProducts,
    requestPending,
  };
}
