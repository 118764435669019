export const getChannel = (): string => {
  return `
    channel {
      rootProductsCategory
      rootPath
      systemId
      limitedStockTreshold
      locale
      cartFulfillmentText
      productFulfillmentText
      allowOrderingAgainstIncomingStock
      showPricesWithVat
      paymentMethodImages {
        alt
        id
      }
      usps {
        imageId
        title
        description
      }
      metaProduct {
        fieldsJson
      }
    }
  `;
};
