<script>
export default {
  computed: {
    paymentMethods() {
      return (this.$channel?.paymentMethodImages || []).map((e) => {
        let o = { ...e };
        o.url = this.$toLitiumMediaUrl(e.id || "");
        return o;
      });
    },
  },
};
</script>

<template>
  <div class="payment-methods">
    <img
      v-for="(paymentMethod, idx) in paymentMethods"
      :key="idx"
      :alt="paymentMethod.alt"
      :src="paymentMethod.url"
    />
  </div>
</template>

<style>
.payment-methods {
  display: flex;
  justify-content: center;
  height: 16px;
  margin: 1rem 1rem 1.25rem;
  gap: 1rem;
}
</style>