import { ref, watch } from 'vue';
import { fetchPageBuilder } from '@drapejs/core';
import useContext from './useContext';
import { buildUrlPathWithQuery } from '../utils';

export default function () {
  const { route, invoke, mitt } = useContext();

  const requestPending = ref(false);

  async function executeCommand(command, args?: any) {
    try {
      requestPending.value = true;
      const request = buildRequest(args);
      const response = await invoke(command, request);
      return response;
    } catch (error) {
      return { error };
    } finally {
      requestPending.value = false;
    }
  }

  function buildRequest(args) {
    const request = {
      ...(args || {}),
      ...fetchPageBuilder(route.protocol, route.host, route.pathname, { ...route.query }, ''),
    };
    request.url = buildUrlPathWithQuery(`${route.protocol}//${route.host}${route.pathname}`, route.query);
    return request;
  }

  watch(
    () => requestPending.value,
    () => {
      mitt?.emit?.('ExecuteCommand:RequestPending', requestPending.value);
    }
  );

  return {
    executeCommand,
  };
}
