import { defineReceiver, defineCommand } from '@drapejs/invoker';
import { gql } from 'graphql-request';
import { request, query, sales, batch } from '@distancify/drapejs-litium';
import { fetchPage } from '@drapejs/core';
import { isNode } from '@drapejs/runtime-context';
import { getChannel } from './gql/channel';

export const userFields = gql`
  fragment UserFields on User {
    isAuthenticated
    organization {
      organizationId
      organizationNo
      organizationName
      legalRegistrationNumber
      navCustomerNumber
      email
      phone
      purchasingCountry
      billingAddress {
        no
        systemId
        careOf
        address1
        address2
        postCode
        city
        country
      }
      deliveryAddresses {
        no
        systemId
        careOf
        address1
        address2
        postCode
        city
        country
      }
    }
    person {
      firstName
      lastName
      email
      phone
    }
    organizations {
      organizationId
      organizationName
    }
  }
`;

export const commands = {
  setCountry: defineCommand<{
    url: string;
    countryId: string;
  }>('setCountry'),
  setLanguage: defineCommand<{
    url: string;
    countryId: string;
    languageId: string;
    ensureCartIntegrity?: boolean;
  }>('setLanguage'),
  refreshUser: defineCommand<{
    url: string;
  }>('refreshUser'),
  login: defineCommand<{
    url: string;
    username: string;
    password: string;
    languageId: string;
  }>('login'),
  logout: defineCommand<{
    url: string;
  }>('logout'),
  changePasswordUponLogin: defineCommand<{
    url: string;
    password: string;
    changePasswordCode: string;
  }>('changePasswordUponLogin'),
  unsubscribePpe: defineCommand<{
    url: string;
    email: string;
    token: string;
  }>('unsubscribePpe'),
  changeOrganization: defineCommand<{
    url: string;
    organizationId: string;
  }>('changeOrganization'),
  recoverAccount: defineCommand<{
    url: string;
    username: string;
  }>('recoverAccount'),
  resetPassword: defineCommand<{
    url: string;
    password: string;
    resetPasswordCode: string;
  }>('resetPassword'),
};

export const receivers = {
  setCountry: defineReceiver(
    commands.setCountry,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(
          gql`
            mutation setCountry($url: String!, $countryId: String) {
              session(url: $url) {
                setCountry(countryId: $countryId) {
                  error
                  cart {
                    ...CartFields
                  }
                }
              }
            }
          `,
          ...sales.withCartFields()
        ),
        {
          url: `${(<any>command).protocol}//${(<any>command).host}${(<any>command).path}`,
          countryId: command.countryId || '',
        }
      );
      const { error, cart } = result.session.setCountry;
      if (error === 'NONE') {
        await this.cache.setItem('__cart', cart);
      }

      return result.session.setCountry;
    },
    'litium'
  ),
  setLanguage: defineReceiver(
    commands.setLanguage,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(
          gql`
            mutation setLanguage(
              $url: String!
              $countryId: String
              $languageId: String!
              $ensureCartIntegrity: Boolean!
            ) {
              session(url: $url) {
                setLanguage(countryId: $countryId, languageId: $languageId, ensureCartIntegrity: $ensureCartIntegrity) {
                  error
                  cart {
                    ...CartFields
                  }
                  user {
                    ...UserFields
                  }
                }
              }
            }
            ${userFields}
          `,
          ...sales.withCartFields()
        ),
        {
          url: `${(<any>command).protocol}//${(<any>command).host}${(<any>command).path}`,
          countryId: command.countryId || '',
          languageId: command.languageId || '',
          ensureCartIntegrity: command.ensureCartIntegrity || false,
        }
      );
      const { error, cart } = result.session.setLanguage;

      if (error === 'NONE') {
        await this.cache.setItem('__cart', cart);
        if (result.session.setLanguage.user) {
          await this.cache.setItem('__user', result.session.setLanguage.user);
        }
      }

      return result.session.setLanguage;
    },
    'litium'
  ),
  refreshUser: defineReceiver(
    commands.refreshUser,
    async function (command, data) {
      try {
        const result = await request(
          this.cache,
          query(
            gql`
              query refreshUser($url: String!) {
                session(url: $url) {
                  user {
                    ...UserFields
                  }
                }
              }
              ${userFields}
            `
          ),
          {
            url: command.url,
          }
        );

        if (result.session.user) {
          await this.cache.setItem('__user', result.session.user);
          return result.session.user;
        } else {
          await this.cache.setItem('__user', { isAuthenticated: false });
          return { isAuthenticated: false };
        }
      } catch (err) {
        console.error(err);
        await this.cache.setItem('__user', { isAuthenticated: false });
        return { isAuthenticated: false };
      }
    },
    'litium'
  ),
  login: defineReceiver(
    commands.login,
    async function (command, data) {
      try {
        const result = await request(
          this.cache,
          query(
            gql`
              mutation login($url: String!, $username: String!, $password: String!, $languageId: String!) {
                session(url: $url) {
                  login(username: $username, password: $password, languageId: $languageId) {
                    error
                    cart {
                      ...CartFields
                    }
                    user {
                      ...UserFields
                    }
                    changePasswordCode
                  }
                }
              }
              ${userFields}
            `,
            ...sales.withCartFields()
          ),
          {
            url: `${(<any>command).protocol}//${(<any>command).host}${(<any>command).path}`,
            username: command.username,
            password: command.password,
            languageId: command.languageId,
          }
        );

        const { error, user, cart } = result.session.login;

        if (error === 'NONE') {
          await this.cache.setItem('__cart', cart);
          await this.cache.setItem('__user', user);
        }

        return result.session.login;
      } catch (err) {
        console.error(err);
        throw 'FAILED';
      }
    },
    'litium'
  ),
  logout: defineReceiver(
    commands.logout,
    async function (command, data) {
      try {
        const result = await request(
          this.cache,
          query(
            gql`
              mutation logout($url: String!) {
                session(url: $url) {
                  logout {
                    cart {
                      ...CartFields
                    }
                    user {
                      ...UserFields
                    }
                  }
                }
              }
              ${userFields}
            `
          ),
          {
            url: command.url,
          }
        );

        const { error, user, cart } = result.session.logout;

        await this.cache.setItem('__cart', cart);
        await this.cache.setItem('__user', user);

        if (error !== 'NONE') {
          throw error;
        }
        return result.session.logout;
      } catch (err) {
        console.error(err);
        throw 'FAILED';
      }
    },
    'litium'
  ),
  changePasswordUponLogin: defineReceiver(
    commands.changePasswordUponLogin,
    async function (command, data) {
      try {
        const result = await request(
          this.cache,
          query(
            gql`
              mutation changePasswordUponLogin($url: String!, $password: String!, $changePasswordCode: String) {
                session(url: $url) {
                  changePasswordUponLogin(password: $password, changePasswordCode: $changePasswordCode) {
                    error
                    cart {
                      ...CartFields
                    }
                    user {
                      ...UserFields
                    }
                  }
                }
              }
              ${userFields}
            `,
            ...sales.withCartFields()
          ),
          {
            url: `${(<any>command).protocol}//${(<any>command).host}${(<any>command).path}`,
            password: command.password,
            changePasswordCode: command.changePasswordCode,
          }
        );

        const { error, user, cart } = result.session.changePasswordUponLogin;

        if (error === 'NONE') {
          await this.cache.setItem('__cart', cart);
          await this.cache.setItem('__user', user);
        }

        return result.session.changePasswordUponLogin;
      } catch (err) {
        console.error(err);
        throw 'FAILED';
      }
    },
    'litium'
  ),
  unsubscribePpe: defineReceiver(
    commands.unsubscribePpe,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(
          gql`
            mutation unsubscribePpe($url: String!, $email: String, $token: String) {
              session(url: $url) {
                unsubscribePpe(email: $email, token: $token)
              }
            }
          `
        ),
        {
          url: command.url || '',
          email: command.email || '',
          token: command.token || '',
        }
      );

      return {
        unsubscribePpe: result.session.unsubscribePpe,
      };
    },
    'litium'
  ),
  changeOrganization: defineReceiver(
    commands.changeOrganization,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(
          gql`
            mutation changeOrganization($url: String!, $organizationId: Guid!) {
              session(url: $url) {
                changeOrganization(organizationId: $organizationId) {
                  error
                  cart {
                    ...CartFields
                  }
                  user {
                    ...UserFields
                  }
                  ${getChannel()}
                }
              }
            }
            ${userFields}
          `,
          ...sales.withCartFields()
        ),
        {
          url: `${(<any>command).protocol}//${(<any>command).host}${(<any>command).path}`,
          organizationId: command.organizationId,
        }
      );

      await this.cache.setItem('__user', result.session.changeOrganization.user);
      await this.cache.setItem('__cart', result.session.changeOrganization.cart);

      return result.session.changeOrganization;
    },
    'litium'
  ),
  recoverAccount: defineReceiver(
    commands.recoverAccount,
    async function (command, data) {
      try {
        const result = await request(
          this.cache,
          query(
            gql`
              mutation recoverAccount($url: String!, $username: String!) {
                session(url: $url) {
                  recoverAccount(username: $username) {
                    error
                  }
                }
              }
            `
          ),
          {
            url: `${(<any>command).protocol}//${(<any>command).host}${(<any>command).path}`,
            username: command.username,
          }
        );

        return result.session.recoverAccount;
      } catch (err) {
        console.error(err);
        throw 'FAILED';
      }
    },
    'litium'
  ),
  resetPassword: defineReceiver(
    commands.resetPassword,
    async function (command, data) {
      try {
        const result = await request(
          this.cache,
          query(
            gql`
              mutation resetPassword($url: String!, $password: String!, $resetPasswordCode: String!) {
                session(url: $url) {
                  resetPassword(password: $password, resetPasswordCode: $resetPasswordCode) {
                    error
                    cart {
                      ...CartFields
                    }
                    user {
                      ...UserFields
                    }
                  }
                }
              }
              ${userFields}
            `,
            ...sales.withCartFields()
          ),
          {
            url: `${(<any>command).protocol}//${(<any>command).host}${(<any>command).path}`,
            password: command.password,
            resetPasswordCode: command.resetPasswordCode || '',
          }
        );

        const { error, user, cart } = result.session.resetPassword;

        if (error === 'NONE') {
          await this.cache.setItem('__cart', cart);
          await this.cache.setItem('__user', user);
        }

        return result.session.resetPassword;
      } catch (err) {
        console.error(err);
        throw 'FAILED';
      }
    },
    'litium'
  ),
};

export const scheduleResetPasswordCodeState = defineReceiver(fetchPage, async function (command: any, data) {
  if (command?.query?.action === 'reset-password') {
    const code = command?.query?.code;
    if (code) {
      await this.invoke(batch.scheduleQuery, {
        query: gql`
            auth {
              resetPasswordCodeState(resetPasswordCode: "${code}")
            }
            `,
        cacheKey: '__resetPasswordCodeState',
      });
    }
  }
  return data;
});

export const scheduleCountrySwitcher = defineReceiver(fetchPage, async function (command: any, data) {
  if (!isNode()) {
    await this.invoke(batch.scheduleQuery, {
      query: gql`
        countrySwitcher {
          geoIpCountryCode
          availableCountries {
            languageId
            languageName
            channelSystemId
            name
            rootPath
            countryId
            culture
            currencySymbol
          }
        }
        `,
      cacheKey: '__countrySwitcher',
    });
  }
  return data;
});
