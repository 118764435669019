import { computed } from 'vue';
import { buildChannelSwitchUrlPathWithQuery } from '@/utils';

import useContext from '@/composables/useContext';
import useChannel from '@/composables/useChannel';
import useShoppingPreference from '@/composables/useShoppingPreference';

export default function () {
  const { cart, route, page, environmentOptions } = useContext();
  const { channel } = useChannel();
  const { availableCountries } = useShoppingPreference();

  const activeCountryOption = computed(() => {
    const cartCountryId = cart?.value?.countryId;
    const channelId = channel.value?.systemId;

    return (availableCountries?.value || []).find(
      (c) => c.countryId == cartCountryId && c.channelSystemId == channelId
    );
  });

  function buildRedirectUrl({ countryId, languageId, useRootPath = false }) {
    const countries = availableCountries?.value || [];
    const countryFallback = countries.find((c) => 'SE' == c.countryId) || countries[0];

    const redirectToChannel = !languageId
      ? countries.find((c) => countryId == c.countryId) || countryFallback
      : countries.find((c) => countryId == c.countryId && languageId == c.languageId) || countryFallback;

    if (activeCountryOption.value && redirectToChannel?.channelSystemId == activeCountryOption.value.channelSystemId) {
      return '';
    }

    let altPath = (page.value.alternates || []).find((a) => {
      const redirectLanguage = redirectToChannel.culture?.toLowerCase();
      const altLanguage = a.lang?.toLowerCase();
      if (redirectLanguage === 'sv-se') {
        return altLanguage === redirectLanguage || altLanguage === 'default';
      } else {
        return altLanguage === redirectLanguage;
      }
    })?.path;

    if (!altPath || useRootPath) {
      return `${route.protocol}//${buildChannelSwitchUrlPathWithQuery(redirectToChannel.rootPath, route.query)}`;
    }

    altPath = altPath.replace(`${environmentOptions.contentRootPath}`, '');

    const redirectUrl = `${route.protocol}//${redirectToChannel.rootPath}${buildChannelSwitchUrlPathWithQuery(
      altPath,
      route.query
    )}`;

    return redirectUrl;
  }

  return {
    buildRedirectUrl,
  };
}
