<script setup>
import useContext from '@/composables/useContext'

const { settings, resolveLink } = useContext()
</script>

<template>
  <div class="ribbon">
    <div class="ribbon__margin">
      <div class="ribbon__promotion">
        {{ settings?.ribbonPromotion }}
      </div>

      <ul class="ribbon__links">
        <li
          class="ribbon__link-item"
          v-for="link in settings?.ribbonLinks"
          :key="link._uid"
        >
          <router-link
            :to="resolveLink(() => link.link).url"
            class="ribbon__link"
          >
            {{ link.label }}
          </router-link>
        </li>
      </ul>

      <div class="ribbon__country-select">
        <ShoppingPreferences />
      </div>
    </div>
  </div>
</template>

<style>
.ribbon {
  background-color: var(--color-main-creme);
  color: var(--color-text-special);
  position: relative;
  z-index: 10;
}

.ribbon--hidden {
  transform: translateX(-100%);
}

.ribbon__margin {
  max-width: 1440px;
  margin: auto;
  position: relative;
}

.ribbon__links {
  list-style-type: none;
  margin: 0 0 0 20px;
  padding: 0;
  position: absolute;
  left: 0;
  top: 0;

  @media (max-width: 1279px) {
    display: none;
  }
}

.ribbon__link-item {
  display: inline-block;
}

.ribbon__link {
  padding: 0 10px;
  line-height: 35px;
  text-decoration: none;
  color: var(--color-text-special);
}

.ribbon__link:hover,
.ribbon__link:active {
  text-decoration: underline;
  color: var(--color-text-special);
}

.ribbon__promotion {
  text-align: center;
  line-height: 35px;
}

.ribbon__country-select {
  position: absolute;
  right: 0;
  top: 0;
  margin: 0 20px 0 0;
  line-height: 35px;

  @media (max-width: 1279px) {
    display: none;
  }
}
</style>
