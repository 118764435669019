<script setup>
import { inject, watch } from 'vue';
import useContext from '@/composables/useContext';

import { activeDesktopFlyoutKey, isSearchOpenKey } from '../../keys';

const {
  settings,
  mitt,
  resolveLink,
} = useContext();

let timer;
const activeDesktopFlyout = inject(activeDesktopFlyoutKey);
const isSearchOpen = inject(isSearchOpenKey)

function clearSearchIfOpen() {
  mitt.emit('close-search')
  mitt.emit('clear-search-input')
}
function clearSection() {
  activeDesktopFlyout.uid = null
}

function toggleSection(item) {
  clearSearchIfOpen();
  if (activeDesktopFlyout.uid === item._uid) {
    if (activeDesktopFlyout.isPinned) {
      activeDesktopFlyout.uid = null;
    } else {
      activeDesktopFlyout.isPinned = true;
    }
  } else {
    activeDesktopFlyout.uid = item._uid;
    activeDesktopFlyout.isPinned = true;
    clearTimeout(timer);
  }
}
function startTimer(item) {
  if (activeDesktopFlyout.uid) {
    // Something's already open, switch faster
    timer = setTimeout(() => {
    clearSearchIfOpen();
    activeDesktopFlyout.uid = item._uid;
  }, 300);
    return;
  }

  timer = setTimeout(() => {
    clearSearchIfOpen();
    activeDesktopFlyout.uid = item._uid;
    activeDesktopFlyout.isPinned = false;
  }, 500);
}
function stopTimer(item) {
  if (timer) {
    clearTimeout(timer);
  }
}

watch(
  () => isSearchOpen.value,
  () => {
    if (isSearchOpen.value) {
      stopTimer()
      clearSection()
    }
  },
)
</script>

<template>
  <ul class="desktop-links">
    <li
      class="desktop-links__link-item"
      v-for="item in settings?.mainMenu"
      :key="item._uid"
    >
      <router-link
        v-if="item.component === 'MenuItem' && !item.phoneOnly"
        :to="resolveLink(() => item.link).url"
        class="desktop-links__link"
      >
        {{item.label}}
      </router-link>
      <a
        v-if="item.component === 'MenuSection' && !item.phoneOnly"
        href="#"
        @mouseenter="startTimer(item)"
        @mouseleave="stopTimer()"
        @click.prevent="toggleSection(item)"
        class="desktop-links__link"
        :class="{'desktop-links__link--open': activeDesktopFlyout === item._uid}"
      >
        {{item.label}}
      </a>
    </li>
  </ul>
</template>

<style>
.desktop-links {
  margin: 0 0 0 12px;
  padding: 0 18px 0 0;
  list-style-type: none;
  display: flex;
  height: 66px;
  align-items: center;

  border-right: 1px solid var(--color-neutrals-40);
}

.desktop-links__link, .desktop-links__link:hover, .desktop-links__link:active, .desktop-links__link:visited {
  display: block;
  line-height: 40px;
  padding: 0 10.5px;
  text-decoration: none;
  color: var(--color-text-standard);
  position: relative;
  cursor: pointer;
}

.desktop-links__link:hover:after, .desktop-links__link--open:after {
  content: '';
  position: absolute;
  left: 10.5px;
  right: 10.5px;
  bottom: 10px;
  border-bottom: 1px solid var(--color-text-standard);
}

</style>