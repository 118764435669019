<script setup>
import { inject, computed, watch } from 'vue';
import { activeDesktopFlyoutKey } from '@/keys';
import useContext from '@/composables/useContext';

const props = defineProps({
  item: Object
});

const {
  route,
  resolveLink,
} = useContext();
const activeDesktopFlyout = inject(activeDesktopFlyoutKey);

const isOpen = computed(() => activeDesktopFlyout.uid === props.item._uid);
const hasLinks = computed(() => props.item?.items?.length > 0);
const promotionWidth = computed(() => {
  return {width: hasLinks.value ? '40%' : '100%'}
});
watch(() => route.pathname, () => {
  if (!activeDesktopFlyout) { return };
  activeDesktopFlyout.isPinned = false;
  activeDesktopFlyout.uid = null;
});

</script>

<template>
  <div class="desktop-flyout" :class="{'desktop-flyout--open': isOpen}">
    <div class="desktop-flyout__margin">
      <ul class="desktop-flyout__links" v-if="hasLinks">
        <li class="desktop-flyout__link-item" v-for="item in props.item.items" :key="item._uid">
            <router-link v-if="item.component === 'MenuItem' && !item.phoneOnly" :to="resolveLink(() => item.link).url" class="desktop-flyout__link" :class="(item.style ? `desktop-flyout__link--${item.style}` : ``)">{{item.label}}</router-link>
        </li>
      </ul>
      <div class="desktop-flyout__promotions" :style="promotionWidth">
        <blocks-repeater :blocks="props.item.promotions" />
      </div>
    </div>
  </div>
</template>

<style>
.desktop-flyout {
  pointer-events: none;
  background-color: var(--color-neutrals-00);
  opacity: 0;
  width: 100%;
  position: absolute;
  transform: translateY(-100%);
  transition: transform 150ms;
}

.desktop-flyout--open {
  opacity: 1;
  pointer-events: all;
  transform: translateY(0);
  box-shadow: 0px 5px 16px rgba(26, 26, 26, 0.05);
}

.desktop-flyout:not(.desktop-flyout--open) {
  transition: transform 150ms, opacity 500ms;
}

.desktop-flyout__margin {
  max-width: 1440px;
  margin: auto;
  display: flex;
}

.desktop-flyout__links {
  column-width: 160px;
  list-style-type: none;
  width: 60%;
  padding-top: 4px;
}

.desktop-flyout__link {
  text-decoration: none;
  color: var(--color-text-standard);
  line-height: 35px;
}

.desktop-flyout__link:hover {
  text-decoration: underline;
}

.desktop-flyout__promotions {
  display: flex;
}

  @media(--desktop) {
    .desktop-flyout__promotions > section {
      flex: 1;
    } 
  }

.desktop-flyout__link.desktop-flyout__link--special {
  color: var(--color-text-special)
}

.desktop-flyout__link.desktop-flyout__link--sale {
  color: var(--color-sale)
}

.desktop-flyout__link.desktop-flyout__link--bold {
  font-family: "ProximaNova-Bold", sans-serif;
  font-weight: 700;
}
</style>