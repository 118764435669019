import { inject, computed } from 'vue';
import { invokeKey } from '@drapejs/core';
import { userKey, userInfoKey } from '@/keys';

import * as userConnector from '@/connectors/user';

export default function () {
  const invoke: any = inject(invokeKey);
  const userInfo: any = inject(userInfoKey);
  const user: any = inject(userKey);

  const phonePrefixes = computed(() => userInfo.phonePrefixes || []);

  const isAuthenticated = computed(() => {
    return user.value?.isAuthenticated || false;
  });

  const organizations = computed(() => {
    return user.value?.organizations || [];
  });

  const purchasingCountry = computed(() => {
    return user.value?.organization?.purchasingCountry || '';
  });

  const fullName = computed(() => {
    return [user.value.person?.firstName, user.value.person?.lastName].filter((r) => r).join(' ');
  });

  const organizationName = computed(() => {
    return user.value?.organization?.organizationName || '';
  });

  async function refreshUser() {
    try {
      const response = await await invoke(userConnector.commands.refreshUser, {
        url: window.location.href,
      });
      return response;
    } catch (err) {
      console.error(err);
    }
  }

  return {
    refreshUser,
    phonePrefixes,
    user,
    isAuthenticated,
    organizations,
    purchasingCountry,
    fullName,
    organizationName,
  };
}
